/* eslint-disable no-console */
import type { ServerError } from '@apollo/client';
import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import type { ErrorResponse } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';

import { AppConfig } from '../../config/AppConfig';
import { ls } from '../../utils/localStorage';
import { errObjs, jwtControl, setAuth } from '../auth/auth';
import { RefreshTokenDocument } from './generated-types';

export { jwtControl } from '../auth/auth';

const OPERATIONS_NOT_REQUIRING_AUTH = [
  'AuthenticateUser',
  'UserRegistration',
  'RefreshToken',
  'PublicNote',
];

export const getJwt = () => {
  const auth = ls.getJson('auth', { encrypted: true });
  return auth?.jwt;
};

let expiredAuthHandler: (errObj: ErrorResponse) => void;
export const setExpiredAuthHandler = (handler: typeof expiredAuthHandler) => {
  expiredAuthHandler = handler;
};

const errorDispatcher = onError((errObj) => {
  if (expiredAuthHandler) {
    expiredAuthHandler(errObj);
  }
});

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(atob(base64));
};

const getExpires = (token: string) => {
  const decoded: any = parseJwt(token);
  return decoded.exp * 1000; // exp is usually in seconds, convert it to milliseconds
};

const shouldAttemptTokenRefresh = (): boolean => {
  const jwt = getJwt();
  if (!jwt) return false;

  const jwtExpires = getExpires(jwt);
  const threeHoursFromNow = Date.now() + 3 * 60 * 60 * 1000;

  if (jwtExpires < threeHoursFromNow) {
    const lastAttempt = ls.getNumber('lastAttempt') ?? 0;
    if (Date.now() - lastAttempt > 5 * 60 * 1000) {
      // 5 minutes gap
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const jwtAuth = jwtControl.getAuth().auth;
      const refreshToken = jwtAuth.refresh;
      if (refreshToken) {
        const refreshExpires = getExpires(refreshToken);
        if (refreshExpires > Date.now()) {
          return true;
        }
      }
    }
  }
  return false;
};

const attemptTokenRefresh = async () => {
  const jwtAuth = jwtControl.getAuth().auth;
  if (jwtAuth && jwtAuth.refresh) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const client = getApolloClient();
    const refreshVariables = {
      refresh: jwtAuth.refresh,
      remember: jwtAuth.remember,
    };
    const res = await client.mutate({
      mutation: RefreshTokenDocument,
      variables: refreshVariables,
    });
    await client.writeQuery({
      query: RefreshTokenDocument,
      variables: refreshVariables,
      data: res.data,
    });
    console.log('retry success', res, client.cache);
    if (
      res.data?.refreshToken &&
      res.data?.refreshToken?.jwt &&
      res.data?.refreshToken?.refresh
    ) {
      const jwt = res.data?.refreshToken?.jwt;
      const refreshT = res.data?.refreshToken?.refresh as string;
      // set auth in local storage
      setAuth(jwtAuth.user, jwt, refreshT, jwtAuth.remember);
    }
    ls.setNumber('lastAttempt', Date.now());
  }
};

const afterQueryLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    (async () => {
      if (
        !OPERATIONS_NOT_REQUIRING_AUTH.includes(
          operation.operationName as string
        ) &&
        shouldAttemptTokenRefresh()
      ) {
        await attemptTokenRefresh();
      }
    })();
    return response;
  });
});

function createApolloClient() {
  const authLink = setContext((req, { headers }) => {
    if (OPERATIONS_NOT_REQUIRING_AUTH.includes(req.operationName as string)) {
      return { headers };
    }

    // get the authentication token from local storage if it exists
    const token = getJwt();

    if (!token) {
      return { headers };
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = new HttpLink({
    uri: AppConfig.graphql_url,
    credentials: 'same-origin',
  });

  return new ApolloClient({
    link: from([errorDispatcher, authLink, afterQueryLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    connectToDevTools: true,
  });
}

let client: ApolloClient<any>;
export const getApolloClient = () => {
  if (!client) {
    client = createApolloClient();
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-underscore-dangle
      (window as any).__APOLLO_CLIENT__ = client;
    }
  }
  return client;
};

const handleJwtExpired = (errObj: ErrorResponse) => {
  if (
    errObj.networkError &&
    [401, 403].includes((errObj.networkError as ServerError).statusCode)
  ) {
    jwtControl.setExpired(true);
    errObjs.push(errObj);
    attemptTokenRefresh();
  }
  // tslint:disable-next-line:no-console
  console.warn('observed graphQLerror', errObj);
  errObj.forward(errObj.operation);
};

setExpiredAuthHandler(handleJwtExpired);
