import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Cursor: any;
  Datetime: any;
  JSON: any;
  UUID: any;
};

export type AdminSubscriptionInput = {
  meta?: InputMaybe<Scalars['JSON']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  userId: Scalars['Int'];
};

export type ApplyTokenInput = {
  details?: InputMaybe<Scalars['JSON']>;
  key: Scalars['String'];
};

export type ApplyTokenPayload = {
  __typename?: 'ApplyTokenPayload';
  organization?: Maybe<Organization>;
};

export type AtVersionInput = {
  versionId?: InputMaybe<Scalars['Int']>;
};

export type AuthenticateUserInput = {
  password: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type AuthenticateUserOtpInput = {
  id: Scalars['String'];
  otpKey: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
};

export type AuthenticateUserOtpPayload = {
  __typename?: 'AuthenticateUserOTPPayload';
  jwt?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<Maybe<Program>>>;
  query?: Maybe<Query>;
  refresh?: Maybe<Scalars['String']>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  user?: Maybe<User>;
  userProfile?: Maybe<UserProfile>;
};

export type AuthenticateUserPayload = {
  __typename?: 'AuthenticateUserPayload';
  jwt?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<Maybe<Program>>>;
  query?: Maybe<Query>;
  refresh?: Maybe<Scalars['String']>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  user?: Maybe<User>;
  userProfile?: Maybe<UserProfile>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** All input for the create `Event` mutation. */
export type CreateEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Event` to be created by this mutation. */
  event: EventInput;
};

/** The output of our create `Event` mutation. */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was created by this mutation. */
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Event` mutation. */
export type CreateEventPayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the create `Interaction` mutation. */
export type CreateInteractionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Interaction` to be created by this mutation. */
  interaction: InteractionInput;
};

/** The output of our create `Interaction` mutation. */
export type CreateInteractionPayload = {
  __typename?: 'CreateInteractionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Interaction` that was created by this mutation. */
  interaction?: Maybe<Interaction>;
  /** An edge for our `Interaction`. May be used by Relay 1. */
  interactionEdge?: Maybe<InteractionsEdge>;
  /** Reads a single `Organization` that is related to this `Interaction`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Interaction`. */
  user?: Maybe<User>;
};


/** The output of our create `Interaction` mutation. */
export type CreateInteractionPayloadInteractionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};

/** All input for the create `InteractionVersion` mutation. */
export type CreateInteractionVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `InteractionVersion` to be created by this mutation. */
  interactionVersion: InteractionVersionInput;
};

/** The output of our create `InteractionVersion` mutation. */
export type CreateInteractionVersionPayload = {
  __typename?: 'CreateInteractionVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Interaction` that is related to this `InteractionVersion`. */
  interaction?: Maybe<Interaction>;
  /** The `InteractionVersion` that was created by this mutation. */
  interactionVersion?: Maybe<InteractionVersion>;
  /** An edge for our `InteractionVersion`. May be used by Relay 1. */
  interactionVersionEdge?: Maybe<InteractionVersionsEdge>;
  /** Reads a single `Organization` that is related to this `InteractionVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  user?: Maybe<User>;
};


/** The output of our create `InteractionVersion` mutation. */
export type CreateInteractionVersionPayloadInteractionVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};

/** All input for the create `Membership` mutation. */
export type CreateMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Membership` to be created by this mutation. */
  membership: MembershipInput;
};

/** The output of our create `Membership` mutation. */
export type CreateMembershipPayload = {
  __typename?: 'CreateMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Membership` that was created by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Reads a single `Organization` that is related to this `Membership`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our create `Membership` mutation. */
export type CreateMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** All input for the create `Note` mutation. */
export type CreateNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Note` to be created by this mutation. */
  note: NoteInput;
};

/** The output of our create `Note` mutation. */
export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Note` that was created by this mutation. */
  note?: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge?: Maybe<NotesEdge>;
  /** Reads a single `Organization` that is related to this `Note`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
};


/** The output of our create `Note` mutation. */
export type CreateNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the create `NoteVersion` mutation. */
export type CreateNoteVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `NoteVersion` to be created by this mutation. */
  noteVersion: NoteVersionInput;
};

/** The output of our create `NoteVersion` mutation. */
export type CreateNoteVersionPayload = {
  __typename?: 'CreateNoteVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Note` that is related to this `NoteVersion`. */
  note?: Maybe<Note>;
  /** The `NoteVersion` that was created by this mutation. */
  noteVersion?: Maybe<NoteVersion>;
  /** An edge for our `NoteVersion`. May be used by Relay 1. */
  noteVersionEdge?: Maybe<NoteVersionsEdge>;
  /** Reads a single `Organization` that is related to this `NoteVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  user?: Maybe<User>;
};


/** The output of our create `NoteVersion` mutation. */
export type CreateNoteVersionPayloadNoteVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};

/** All input for the create `Organization` mutation. */
export type CreateOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Organization` to be created by this mutation. */
  organization: OrganizationInput;
};

/** The output of our create `Organization` mutation. */
export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Organization` that was created by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Reads a single `User` that is related to this `Organization`. */
  owner?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Organization` mutation. */
export type CreateOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the create `Program` mutation. */
export type CreateProgramInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Program` to be created by this mutation. */
  program: ProgramInput;
};

/** The output of our create `Program` mutation. */
export type CreateProgramPayload = {
  __typename?: 'CreateProgramPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Organization` that is related to this `Program`. */
  org?: Maybe<Organization>;
  /** The `Program` that was created by this mutation. */
  program?: Maybe<Program>;
  /** An edge for our `Program`. May be used by Relay 1. */
  programEdge?: Maybe<ProgramsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Program`. */
  user?: Maybe<User>;
};


/** The output of our create `Program` mutation. */
export type CreateProgramPayloadProgramEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};

/** All input for the create `ProgramVersion` mutation. */
export type CreateProgramVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProgramVersion` to be created by this mutation. */
  programVersion: ProgramVersionInput;
};

/** The output of our create `ProgramVersion` mutation. */
export type CreateProgramVersionPayload = {
  __typename?: 'CreateProgramVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Organization` that is related to this `ProgramVersion`. */
  org?: Maybe<Organization>;
  /** Reads a single `Program` that is related to this `ProgramVersion`. */
  program?: Maybe<Program>;
  /** The `ProgramVersion` that was created by this mutation. */
  programVersion?: Maybe<ProgramVersion>;
  /** An edge for our `ProgramVersion`. May be used by Relay 1. */
  programVersionEdge?: Maybe<ProgramVersionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  user?: Maybe<User>;
};


/** The output of our create `ProgramVersion` mutation. */
export type CreateProgramVersionPayloadProgramVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};

/** All input for the create `State` mutation. */
export type CreateStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `State` to be created by this mutation. */
  state: StateInput;
};

/** The output of our create `State` mutation. */
export type CreateStatePayload = {
  __typename?: 'CreateStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Interaction` that is related to this `State`. */
  interaction?: Maybe<Interaction>;
  /** Reads a single `Program` that is related to this `State`. */
  program?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was created by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
  /** Reads a single `User` that is related to this `State`. */
  user?: Maybe<User>;
};


/** The output of our create `State` mutation. */
export type CreateStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the create `Subscription` mutation. */
export type CreateSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Subscription` to be created by this mutation. */
  subscription: SubscriptionInput;
};

/** The output of our create `Subscription` mutation. */
export type CreateSubscriptionPayload = {
  __typename?: 'CreateSubscriptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Organization` that is related to this `Subscription`. */
  org?: Maybe<Organization>;
  /** Reads a single `Program` that is related to this `Subscription`. */
  program?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Subscription` that was created by this mutation. */
  subscription?: Maybe<Subscription>;
  /** An edge for our `Subscription`. May be used by Relay 1. */
  subscriptionEdge?: Maybe<SubscriptionsEdge>;
  /** Reads a single `User` that is related to this `Subscription`. */
  user?: Maybe<User>;
};


/** The output of our create `Subscription` mutation. */
export type CreateSubscriptionPayloadSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};

/** All input for the create `Token` mutation. */
export type CreateTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Token` to be created by this mutation. */
  token: TokenInput;
};

/** The output of our create `Token` mutation. */
export type CreateTokenPayload = {
  __typename?: 'CreateTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Token`. */
  grantedBy?: Maybe<User>;
  /** Reads a single `Note` that is related to this `Token`. */
  note?: Maybe<Note>;
  /** Reads a single `Organization` that is related to this `Token`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Token` that was created by this mutation. */
  token?: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge?: Maybe<TokensEdge>;
};


/** The output of our create `Token` mutation. */
export type CreateTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** All input for the create `TypeormMetadatum` mutation. */
export type CreateTypeormMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TypeormMetadatum` to be created by this mutation. */
  typeormMetadatum: TypeormMetadatumInput;
};

/** The output of our create `TypeormMetadatum` mutation. */
export type CreateTypeormMetadatumPayload = {
  __typename?: 'CreateTypeormMetadatumPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TypeormMetadatum` that was created by this mutation. */
  typeormMetadatum?: Maybe<TypeormMetadatum>;
  /** An edge for our `TypeormMetadatum`. May be used by Relay 1. */
  typeormMetadatumEdge?: Maybe<TypeormMetadataEdge>;
};


/** The output of our create `TypeormMetadatum` mutation. */
export type CreateTypeormMetadatumPayloadTypeormMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<TypeormMetadataOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the create `UserProfile` mutation. */
export type CreateUserProfileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserProfile` to be created by this mutation. */
  userProfile: UserProfileInput;
};

/** The output of our create `UserProfile` mutation. */
export type CreateUserProfilePayload = {
  __typename?: 'CreateUserProfilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserProfile`. */
  user?: Maybe<User>;
  /** The `UserProfile` that was created by this mutation. */
  userProfile?: Maybe<UserProfile>;
  /** An edge for our `UserProfile`. May be used by Relay 1. */
  userProfileEdge?: Maybe<UserProfilesEdge>;
};


/** The output of our create `UserProfile` mutation. */
export type CreateUserProfilePayloadUserProfileEdgeArgs = {
  orderBy?: InputMaybe<Array<UserProfilesOrderBy>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

/** All input for the `deleteEventByNodeId` mutation. */
export type DeleteEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Event` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEvent` mutation. */
export type DeleteEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Event` mutation. */
export type DeleteEventPayload = {
  __typename?: 'DeleteEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedEventNodeId?: Maybe<Scalars['ID']>;
  /** The `Event` that was deleted by this mutation. */
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Event` mutation. */
export type DeleteEventPayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the `deleteInteractionByNodeId` mutation. */
export type DeleteInteractionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Interaction` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteInteraction` mutation. */
export type DeleteInteractionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Interaction` mutation. */
export type DeleteInteractionPayload = {
  __typename?: 'DeleteInteractionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedInteractionNodeId?: Maybe<Scalars['ID']>;
  /** The `Interaction` that was deleted by this mutation. */
  interaction?: Maybe<Interaction>;
  /** An edge for our `Interaction`. May be used by Relay 1. */
  interactionEdge?: Maybe<InteractionsEdge>;
  /** Reads a single `Organization` that is related to this `Interaction`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Interaction`. */
  user?: Maybe<User>;
};


/** The output of our delete `Interaction` mutation. */
export type DeleteInteractionPayloadInteractionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};

/** All input for the `deleteInteractionVersionByNodeId` mutation. */
export type DeleteInteractionVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InteractionVersion` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteInteractionVersion` mutation. */
export type DeleteInteractionVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `InteractionVersion` mutation. */
export type DeleteInteractionVersionPayload = {
  __typename?: 'DeleteInteractionVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedInteractionVersionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Interaction` that is related to this `InteractionVersion`. */
  interaction?: Maybe<Interaction>;
  /** The `InteractionVersion` that was deleted by this mutation. */
  interactionVersion?: Maybe<InteractionVersion>;
  /** An edge for our `InteractionVersion`. May be used by Relay 1. */
  interactionVersionEdge?: Maybe<InteractionVersionsEdge>;
  /** Reads a single `Organization` that is related to this `InteractionVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  user?: Maybe<User>;
};


/** The output of our delete `InteractionVersion` mutation. */
export type DeleteInteractionVersionPayloadInteractionVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};

/** All input for the `deleteMembershipByNodeId` mutation. */
export type DeleteMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Membership` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMembership` mutation. */
export type DeleteMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Membership` mutation. */
export type DeleteMembershipPayload = {
  __typename?: 'DeleteMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMembershipNodeId?: Maybe<Scalars['ID']>;
  /** The `Membership` that was deleted by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Reads a single `Organization` that is related to this `Membership`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our delete `Membership` mutation. */
export type DeleteMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** All input for the `deleteNoteByNodeId` mutation. */
export type DeleteNoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Note` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNote` mutation. */
export type DeleteNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Note` mutation. */
export type DeleteNotePayload = {
  __typename?: 'DeleteNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedNoteNodeId?: Maybe<Scalars['ID']>;
  /** The `Note` that was deleted by this mutation. */
  note?: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge?: Maybe<NotesEdge>;
  /** Reads a single `Organization` that is related to this `Note`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
};


/** The output of our delete `Note` mutation. */
export type DeleteNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the `deleteNoteVersionByNodeId` mutation. */
export type DeleteNoteVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NoteVersion` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNoteVersion` mutation. */
export type DeleteNoteVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `NoteVersion` mutation. */
export type DeleteNoteVersionPayload = {
  __typename?: 'DeleteNoteVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedNoteVersionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Note` that is related to this `NoteVersion`. */
  note?: Maybe<Note>;
  /** The `NoteVersion` that was deleted by this mutation. */
  noteVersion?: Maybe<NoteVersion>;
  /** An edge for our `NoteVersion`. May be used by Relay 1. */
  noteVersionEdge?: Maybe<NoteVersionsEdge>;
  /** Reads a single `Organization` that is related to this `NoteVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  user?: Maybe<User>;
};


/** The output of our delete `NoteVersion` mutation. */
export type DeleteNoteVersionPayloadNoteVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};

/** All input for the `deleteOrganizationByNodeId` mutation. */
export type DeleteOrganizationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Organization` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOrganizationByUrl` mutation. */
export type DeleteOrganizationByUrlInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

/** All input for the `deleteOrganization` mutation. */
export type DeleteOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Organization` mutation. */
export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOrganizationNodeId?: Maybe<Scalars['ID']>;
  /** The `Organization` that was deleted by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Reads a single `User` that is related to this `Organization`. */
  owner?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Organization` mutation. */
export type DeleteOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `deleteProgramByNodeId` mutation. */
export type DeleteProgramByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Program` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProgramBySlugAndOrgId` mutation. */
export type DeleteProgramBySlugAndOrgIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orgId: Scalars['Int'];
  slug: Scalars['String'];
};

/** All input for the `deleteProgram` mutation. */
export type DeleteProgramInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Program` mutation. */
export type DeleteProgramPayload = {
  __typename?: 'DeleteProgramPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProgramNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Organization` that is related to this `Program`. */
  org?: Maybe<Organization>;
  /** The `Program` that was deleted by this mutation. */
  program?: Maybe<Program>;
  /** An edge for our `Program`. May be used by Relay 1. */
  programEdge?: Maybe<ProgramsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Program`. */
  user?: Maybe<User>;
};


/** The output of our delete `Program` mutation. */
export type DeleteProgramPayloadProgramEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};

/** All input for the `deleteProgramVersionByNodeId` mutation. */
export type DeleteProgramVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProgramVersion` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProgramVersion` mutation. */
export type DeleteProgramVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ProgramVersion` mutation. */
export type DeleteProgramVersionPayload = {
  __typename?: 'DeleteProgramVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProgramVersionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Organization` that is related to this `ProgramVersion`. */
  org?: Maybe<Organization>;
  /** Reads a single `Program` that is related to this `ProgramVersion`. */
  program?: Maybe<Program>;
  /** The `ProgramVersion` that was deleted by this mutation. */
  programVersion?: Maybe<ProgramVersion>;
  /** An edge for our `ProgramVersion`. May be used by Relay 1. */
  programVersionEdge?: Maybe<ProgramVersionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  user?: Maybe<User>;
};


/** The output of our delete `ProgramVersion` mutation. */
export type DeleteProgramVersionPayloadProgramVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};

/** All input for the `deleteStateByNodeId` mutation. */
export type DeleteStateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `State` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteStateByUserIdAndProgramIdAndInteractionId` mutation. */
export type DeleteStateByUserIdAndProgramIdAndInteractionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  interactionId: Scalars['Int'];
  programId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** All input for the `deleteState` mutation. */
export type DeleteStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `State` mutation. */
export type DeleteStatePayload = {
  __typename?: 'DeleteStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedStateNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Interaction` that is related to this `State`. */
  interaction?: Maybe<Interaction>;
  /** Reads a single `Program` that is related to this `State`. */
  program?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was deleted by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
  /** Reads a single `User` that is related to this `State`. */
  user?: Maybe<User>;
};


/** The output of our delete `State` mutation. */
export type DeleteStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the `deleteSubscriptionByNodeId` mutation. */
export type DeleteSubscriptionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Subscription` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSubscriptionByUserIdAndProgramId` mutation. */
export type DeleteSubscriptionByUserIdAndProgramIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  programId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** All input for the `deleteSubscription` mutation. */
export type DeleteSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Subscription` mutation. */
export type DeleteSubscriptionPayload = {
  __typename?: 'DeleteSubscriptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSubscriptionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Organization` that is related to this `Subscription`. */
  org?: Maybe<Organization>;
  /** Reads a single `Program` that is related to this `Subscription`. */
  program?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Subscription` that was deleted by this mutation. */
  subscription?: Maybe<Subscription>;
  /** An edge for our `Subscription`. May be used by Relay 1. */
  subscriptionEdge?: Maybe<SubscriptionsEdge>;
  /** Reads a single `User` that is related to this `Subscription`. */
  user?: Maybe<User>;
};


/** The output of our delete `Subscription` mutation. */
export type DeleteSubscriptionPayloadSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};

/** All input for the `deleteTokenByNodeId` mutation. */
export type DeleteTokenByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Token` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteToken` mutation. */
export type DeleteTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Token` mutation. */
export type DeleteTokenPayload = {
  __typename?: 'DeleteTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTokenNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `User` that is related to this `Token`. */
  grantedBy?: Maybe<User>;
  /** Reads a single `Note` that is related to this `Token`. */
  note?: Maybe<Note>;
  /** Reads a single `Organization` that is related to this `Token`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Token` that was deleted by this mutation. */
  token?: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge?: Maybe<TokensEdge>;
};


/** The output of our delete `Token` mutation. */
export type DeleteTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** All input for the `deleteUserByNodeId` mutation. */
export type DeleteUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteUserProfileByEmail` mutation. */
export type DeleteUserProfileByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** All input for the `deleteUserProfileByMobile` mutation. */
export type DeleteUserProfileByMobileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  mobile: Scalars['String'];
};

/** All input for the `deleteUserProfileByNodeId` mutation. */
export type DeleteUserProfileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserProfile` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserProfileByUserId` mutation. */
export type DeleteUserProfileByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
};

/** All input for the `deleteUserProfile` mutation. */
export type DeleteUserProfileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UserProfile` mutation. */
export type DeleteUserProfilePayload = {
  __typename?: 'DeleteUserProfilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserProfileNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserProfile`. */
  user?: Maybe<User>;
  /** The `UserProfile` that was deleted by this mutation. */
  userProfile?: Maybe<UserProfile>;
  /** An edge for our `UserProfile`. May be used by Relay 1. */
  userProfileEdge?: Maybe<UserProfilesEdge>;
};


/** The output of our delete `UserProfile` mutation. */
export type DeleteUserProfilePayloadUserProfileEdgeArgs = {
  orderBy?: InputMaybe<Array<UserProfilesOrderBy>>;
};

export type DisableTokenInput = {
  key: Scalars['String'];
};

export type DisableTokenPayload = {
  __typename?: 'DisableTokenPayload';
  token?: Maybe<Token>;
};

export type Event = Node & {
  __typename?: 'Event';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  interactionId: Scalars['Int'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  payload: Scalars['JSON'];
  programId: Scalars['Int'];
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  userId: Scalars['Int'];
};

/** A condition to be used against `Event` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EventCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `interactionId` field. */
  interactionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `programId` field. */
  programId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Event` object types. All fields are combined with a logical ‘and.’ */
export type EventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EventFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `interactionId` field. */
  interactionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EventFilter>>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `programId` field. */
  programId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Event` */
export type EventInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  interactionId?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Event`. Fields that are set will be updated. */
export type EventPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  interactionId?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Event` values. */
export type EventsConnection = {
  __typename?: 'EventsConnection';
  /** A list of edges which contains the `Event` and cursor to aid in pagination. */
  edges: Array<EventsEdge>;
  /** A list of `Event` objects. */
  nodes: Array<Maybe<Event>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Event` edge in the connection. */
export type EventsEdge = {
  __typename?: 'EventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Event` at the end of the edge. */
  node?: Maybe<Event>;
};

/** Methods to use when ordering `Event`. */
export enum EventsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InteractionIdAsc = 'INTERACTION_ID_ASC',
  InteractionIdDesc = 'INTERACTION_ID_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProgramIdAsc = 'PROGRAM_ID_ASC',
  ProgramIdDesc = 'PROGRAM_ID_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type GenerateTokenInput = {
  details?: InputMaybe<Scalars['JSON']>;
  orgId?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type GenerateTokenPayload = {
  __typename?: 'GenerateTokenPayload';
  token?: Maybe<Token>;
};

/** Indicates whether archived items should be included in the results or not. */
export enum IncludeArchivedOption {
  /** Only include archived items (i.e. exclude non-archived items). */
  Exclusively = 'EXCLUSIVELY',
  /** If there is a parent GraphQL record and it is archived then this is equivalent to YES, in all other cases this is equivalent to NO. */
  Inherit = 'INHERIT',
  /** Exclude archived items. */
  No = 'NO',
  /** Include archived items. */
  Yes = 'YES'
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Interaction = Node & {
  __typename?: 'Interaction';
  access: InteractionAccessEnum;
  atVersion?: Maybe<Interaction>;
  branch?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  interactionVersions: InteractionVersionsConnection;
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `Interaction`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: InteractionStateEnum;
  /** Reads and enables pagination through a set of `State`. */
  states: StatesConnection;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Interaction`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};


export type InteractionAtVersionArgs = {
  input: AtVersionInput;
};


export type InteractionInteractionVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


export type InteractionStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

export enum InteractionAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against InteractionAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type InteractionAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InteractionAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InteractionAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InteractionAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InteractionAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InteractionAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InteractionAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InteractionAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InteractionAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InteractionAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InteractionAccessEnum>>;
};

export type InteractionCompletionCountsPayload = {
  __typename?: 'InteractionCompletionCountsPayload';
  interactionCounts: Array<InteractionCount>;
};

export type InteractionCompletionInput = {
  endDate?: InputMaybe<Scalars['String']>;
  excludeUserIds?: InputMaybe<Array<Scalars['Int']>>;
  includeUserIds?: InputMaybe<Array<Scalars['Int']>>;
  programId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
};

/**
 * A condition to be used against `Interaction` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type InteractionCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<InteractionAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<InteractionStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

export type InteractionCount = {
  __typename?: 'InteractionCount';
  completionCount: Scalars['Int'];
  interactionId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};

/** A filter to be used against `Interaction` object types. All fields are combined with a logical ‘and.’ */
export type InteractionFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<InteractionAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InteractionFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InteractionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InteractionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<InteractionStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Interaction` */
export type InteractionInput = {
  access?: InputMaybe<InteractionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InteractionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Interaction`. Fields that are set will be updated. */
export type InteractionPatch = {
  access?: InputMaybe<InteractionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InteractionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum InteractionStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against InteractionStateEnum fields. All fields are combined with a logical ‘and.’ */
export type InteractionStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InteractionStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InteractionStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InteractionStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InteractionStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InteractionStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InteractionStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InteractionStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InteractionStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InteractionStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InteractionStateEnum>>;
};

export type InteractionVersion = Node & {
  __typename?: 'InteractionVersion';
  access: InteractionVersionAccessEnum;
  branch?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  editor?: Maybe<User>;
  editorId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** Reads a single `Interaction` that is related to this `InteractionVersion`. */
  interaction?: Maybe<Interaction>;
  interactionId: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `InteractionVersion`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: InteractionVersionStateEnum;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export enum InteractionVersionAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against InteractionVersionAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type InteractionVersionAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InteractionVersionAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InteractionVersionAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InteractionVersionAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InteractionVersionAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InteractionVersionAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InteractionVersionAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InteractionVersionAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InteractionVersionAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InteractionVersionAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InteractionVersionAccessEnum>>;
};

/**
 * A condition to be used against `InteractionVersion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type InteractionVersionCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<InteractionVersionAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `editorId` field. */
  editorId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `interactionId` field. */
  interactionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<InteractionVersionStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `InteractionVersion` object types. All fields are combined with a logical ‘and.’ */
export type InteractionVersionFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<InteractionVersionAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InteractionVersionFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `editorId` field. */
  editorId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `interactionId` field. */
  interactionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InteractionVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InteractionVersionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<InteractionVersionStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `InteractionVersion` */
export type InteractionVersionInput = {
  access?: InputMaybe<InteractionVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interactionId: Scalars['Int'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InteractionVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `InteractionVersion`. Fields that are set will be updated. */
export type InteractionVersionPatch = {
  access?: InputMaybe<InteractionVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interactionId?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InteractionVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

export enum InteractionVersionStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against InteractionVersionStateEnum fields. All fields are combined with a logical ‘and.’ */
export type InteractionVersionStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InteractionVersionStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InteractionVersionStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InteractionVersionStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InteractionVersionStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InteractionVersionStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InteractionVersionStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InteractionVersionStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InteractionVersionStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InteractionVersionStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InteractionVersionStateEnum>>;
};

/** A connection to a list of `InteractionVersion` values. */
export type InteractionVersionsConnection = {
  __typename?: 'InteractionVersionsConnection';
  /** A list of edges which contains the `InteractionVersion` and cursor to aid in pagination. */
  edges: Array<InteractionVersionsEdge>;
  /** A list of `InteractionVersion` objects. */
  nodes: Array<Maybe<InteractionVersion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InteractionVersion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InteractionVersion` edge in the connection. */
export type InteractionVersionsEdge = {
  __typename?: 'InteractionVersionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InteractionVersion` at the end of the edge. */
  node?: Maybe<InteractionVersion>;
};

/** Methods to use when ordering `InteractionVersion`. */
export enum InteractionVersionsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EditorIdAsc = 'EDITOR_ID_ASC',
  EditorIdDesc = 'EDITOR_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InteractionIdAsc = 'INTERACTION_ID_ASC',
  InteractionIdDesc = 'INTERACTION_ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

/** A connection to a list of `Interaction` values. */
export type InteractionsConnection = {
  __typename?: 'InteractionsConnection';
  /** A list of edges which contains the `Interaction` and cursor to aid in pagination. */
  edges: Array<InteractionsEdge>;
  /** A list of `Interaction` objects. */
  nodes: Array<Maybe<Interaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Interaction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Interaction` edge in the connection. */
export type InteractionsEdge = {
  __typename?: 'InteractionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Interaction` at the end of the edge. */
  node?: Maybe<Interaction>;
};

/** Methods to use when ordering `Interaction`. */
export enum InteractionsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']>>;
};

export type Membership = Node & {
  __typename?: 'Membership';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `Membership`. */
  org?: Maybe<Organization>;
  orgId: Scalars['Int'];
  payload: Scalars['JSON'];
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `Membership` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MembershipCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Membership` object types. All fields are combined with a logical ‘and.’ */
export type MembershipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MembershipFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MembershipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MembershipFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Membership` */
export type MembershipInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  orgId: Scalars['Int'];
  payload?: InputMaybe<Scalars['JSON']>;
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Membership`. Fields that are set will be updated. */
export type MembershipPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Membership` values. */
export type MembershipsConnection = {
  __typename?: 'MembershipsConnection';
  /** A list of edges which contains the `Membership` and cursor to aid in pagination. */
  edges: Array<MembershipsEdge>;
  /** A list of `Membership` objects. */
  nodes: Array<Maybe<Membership>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Membership` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Membership` edge in the connection. */
export type MembershipsEdge = {
  __typename?: 'MembershipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Membership` at the end of the edge. */
  node?: Maybe<Membership>;
};

/** Methods to use when ordering `Membership`. */
export enum MembershipsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  adminCreateSubscription?: Maybe<CreateSubscriptionPayload>;
  applyToken?: Maybe<ApplyTokenPayload>;
  authenticateUser?: Maybe<AuthenticateUserPayload>;
  authenticateUserOTP?: Maybe<AuthenticateUserOtpPayload>;
  /** Creates a single `Event`. */
  createEvent?: Maybe<CreateEventPayload>;
  /** Creates a single `Interaction`. */
  createInteraction?: Maybe<CreateInteractionPayload>;
  /** Creates a single `InteractionVersion`. */
  createInteractionVersion?: Maybe<CreateInteractionVersionPayload>;
  /** Creates a single `Membership`. */
  createMembership?: Maybe<CreateMembershipPayload>;
  /** Creates a single `Note`. */
  createNote?: Maybe<CreateNotePayload>;
  /** Creates a single `NoteVersion`. */
  createNoteVersion?: Maybe<CreateNoteVersionPayload>;
  /** Creates a single `Organization`. */
  createOrganization?: Maybe<CreateOrganizationPayload>;
  /** Creates a single `Program`. */
  createProgram?: Maybe<CreateProgramPayload>;
  /** Creates a single `ProgramVersion`. */
  createProgramVersion?: Maybe<CreateProgramVersionPayload>;
  /** Creates a single `State`. */
  createState?: Maybe<CreateStatePayload>;
  /** Creates a single `Subscription`. */
  createSubscription?: Maybe<CreateSubscriptionPayload>;
  /** Creates a single `Token`. */
  createToken?: Maybe<CreateTokenPayload>;
  /** Creates a single `TypeormMetadatum`. */
  createTypeormMetadatum?: Maybe<CreateTypeormMetadatumPayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `UserProfile`. */
  createUserProfile?: Maybe<CreateUserProfilePayload>;
  /** Deletes a single `Event` using a unique key. */
  deleteEvent?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Event` using its globally unique id. */
  deleteEventByNodeId?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Interaction` using a unique key. */
  deleteInteraction?: Maybe<DeleteInteractionPayload>;
  /** Deletes a single `Interaction` using its globally unique id. */
  deleteInteractionByNodeId?: Maybe<DeleteInteractionPayload>;
  /** Deletes a single `InteractionVersion` using a unique key. */
  deleteInteractionVersion?: Maybe<DeleteInteractionVersionPayload>;
  /** Deletes a single `InteractionVersion` using its globally unique id. */
  deleteInteractionVersionByNodeId?: Maybe<DeleteInteractionVersionPayload>;
  /** Deletes a single `Membership` using a unique key. */
  deleteMembership?: Maybe<DeleteMembershipPayload>;
  /** Deletes a single `Membership` using its globally unique id. */
  deleteMembershipByNodeId?: Maybe<DeleteMembershipPayload>;
  /** Deletes a single `Note` using a unique key. */
  deleteNote?: Maybe<DeleteNotePayload>;
  /** Deletes a single `Note` using its globally unique id. */
  deleteNoteByNodeId?: Maybe<DeleteNotePayload>;
  /** Deletes a single `NoteVersion` using a unique key. */
  deleteNoteVersion?: Maybe<DeleteNoteVersionPayload>;
  /** Deletes a single `NoteVersion` using its globally unique id. */
  deleteNoteVersionByNodeId?: Maybe<DeleteNoteVersionPayload>;
  /** Deletes a single `Organization` using a unique key. */
  deleteOrganization?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `Organization` using its globally unique id. */
  deleteOrganizationByNodeId?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `Organization` using a unique key. */
  deleteOrganizationByUrl?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `Program` using a unique key. */
  deleteProgram?: Maybe<DeleteProgramPayload>;
  /** Deletes a single `Program` using its globally unique id. */
  deleteProgramByNodeId?: Maybe<DeleteProgramPayload>;
  /** Deletes a single `Program` using a unique key. */
  deleteProgramBySlugAndOrgId?: Maybe<DeleteProgramPayload>;
  /** Deletes a single `ProgramVersion` using a unique key. */
  deleteProgramVersion?: Maybe<DeleteProgramVersionPayload>;
  /** Deletes a single `ProgramVersion` using its globally unique id. */
  deleteProgramVersionByNodeId?: Maybe<DeleteProgramVersionPayload>;
  /** Deletes a single `State` using a unique key. */
  deleteState?: Maybe<DeleteStatePayload>;
  /** Deletes a single `State` using its globally unique id. */
  deleteStateByNodeId?: Maybe<DeleteStatePayload>;
  /** Deletes a single `State` using a unique key. */
  deleteStateByUserIdAndProgramIdAndInteractionId?: Maybe<DeleteStatePayload>;
  /** Deletes a single `Subscription` using a unique key. */
  deleteSubscription?: Maybe<DeleteSubscriptionPayload>;
  /** Deletes a single `Subscription` using its globally unique id. */
  deleteSubscriptionByNodeId?: Maybe<DeleteSubscriptionPayload>;
  /** Deletes a single `Subscription` using a unique key. */
  deleteSubscriptionByUserIdAndProgramId?: Maybe<DeleteSubscriptionPayload>;
  /** Deletes a single `Token` using a unique key. */
  deleteToken?: Maybe<DeleteTokenPayload>;
  /** Deletes a single `Token` using its globally unique id. */
  deleteTokenByNodeId?: Maybe<DeleteTokenPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUserByNodeId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserProfile` using a unique key. */
  deleteUserProfile?: Maybe<DeleteUserProfilePayload>;
  /** Deletes a single `UserProfile` using a unique key. */
  deleteUserProfileByEmail?: Maybe<DeleteUserProfilePayload>;
  /** Deletes a single `UserProfile` using a unique key. */
  deleteUserProfileByMobile?: Maybe<DeleteUserProfilePayload>;
  /** Deletes a single `UserProfile` using its globally unique id. */
  deleteUserProfileByNodeId?: Maybe<DeleteUserProfilePayload>;
  /** Deletes a single `UserProfile` using a unique key. */
  deleteUserProfileByUserId?: Maybe<DeleteUserProfilePayload>;
  disableToken?: Maybe<DisableTokenPayload>;
  generateToken?: Maybe<GenerateTokenPayload>;
  program?: Maybe<Program>;
  publishInteraction?: Maybe<PublishInteractionPayload>;
  publishNote?: Maybe<PublishNotePayload>;
  publishProgram?: Maybe<PublishProgramPayload>;
  refreshToken?: Maybe<RefreshTokenPayload>;
  registerUser?: Maybe<RegisterUserPayload>;
  requestOTP?: Maybe<RequestOtpPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  resubscribe?: Maybe<CreateSubscriptionPayload>;
  setEmail?: Maybe<SetEmailPayload>;
  setFirstName?: Maybe<SetFirstNamePayload>;
  setMobile?: Maybe<SetMobilePayload>;
  startResetPassword?: Maybe<StartResetPasswordPayload>;
  /** Updates a single `Event` using a unique key and a patch. */
  updateEvent?: Maybe<UpdateEventPayload>;
  /** Updates a single `Event` using its globally unique id and a patch. */
  updateEventByNodeId?: Maybe<UpdateEventPayload>;
  /** Updates a single `Interaction` using a unique key and a patch. */
  updateInteraction?: Maybe<UpdateInteractionPayload>;
  /** Updates a single `Interaction` using its globally unique id and a patch. */
  updateInteractionByNodeId?: Maybe<UpdateInteractionPayload>;
  /** Updates a single `InteractionVersion` using a unique key and a patch. */
  updateInteractionVersion?: Maybe<UpdateInteractionVersionPayload>;
  /** Updates a single `InteractionVersion` using its globally unique id and a patch. */
  updateInteractionVersionByNodeId?: Maybe<UpdateInteractionVersionPayload>;
  /** Updates a single `Membership` using a unique key and a patch. */
  updateMembership?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `Membership` using its globally unique id and a patch. */
  updateMembershipByNodeId?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `Note` using a unique key and a patch. */
  updateNote?: Maybe<UpdateNotePayload>;
  /** Updates a single `Note` using its globally unique id and a patch. */
  updateNoteByNodeId?: Maybe<UpdateNotePayload>;
  /** Updates a single `NoteVersion` using a unique key and a patch. */
  updateNoteVersion?: Maybe<UpdateNoteVersionPayload>;
  /** Updates a single `NoteVersion` using its globally unique id and a patch. */
  updateNoteVersionByNodeId?: Maybe<UpdateNoteVersionPayload>;
  /** Updates a single `Organization` using a unique key and a patch. */
  updateOrganization?: Maybe<UpdateOrganizationPayload>;
  /** Updates a single `Organization` using its globally unique id and a patch. */
  updateOrganizationByNodeId?: Maybe<UpdateOrganizationPayload>;
  /** Updates a single `Organization` using a unique key and a patch. */
  updateOrganizationByUrl?: Maybe<UpdateOrganizationPayload>;
  /** Updates a single `Program` using a unique key and a patch. */
  updateProgram?: Maybe<UpdateProgramPayload>;
  /** Updates a single `Program` using its globally unique id and a patch. */
  updateProgramByNodeId?: Maybe<UpdateProgramPayload>;
  /** Updates a single `Program` using a unique key and a patch. */
  updateProgramBySlugAndOrgId?: Maybe<UpdateProgramPayload>;
  /** Updates a single `ProgramVersion` using a unique key and a patch. */
  updateProgramVersion?: Maybe<UpdateProgramVersionPayload>;
  /** Updates a single `ProgramVersion` using its globally unique id and a patch. */
  updateProgramVersionByNodeId?: Maybe<UpdateProgramVersionPayload>;
  /** Updates a single `State` using a unique key and a patch. */
  updateState?: Maybe<UpdateStatePayload>;
  /** Updates a single `State` using its globally unique id and a patch. */
  updateStateByNodeId?: Maybe<UpdateStatePayload>;
  /** Updates a single `State` using a unique key and a patch. */
  updateStateByUserIdAndProgramIdAndInteractionId?: Maybe<UpdateStatePayload>;
  /** Updates a single `Subscription` using a unique key and a patch. */
  updateSubscription?: Maybe<UpdateSubscriptionPayload>;
  /** Updates a single `Subscription` using its globally unique id and a patch. */
  updateSubscriptionByNodeId?: Maybe<UpdateSubscriptionPayload>;
  /** Updates a single `Subscription` using a unique key and a patch. */
  updateSubscriptionByUserIdAndProgramId?: Maybe<UpdateSubscriptionPayload>;
  /** Updates a single `Token` using a unique key and a patch. */
  updateToken?: Maybe<UpdateTokenPayload>;
  /** Updates a single `Token` using its globally unique id and a patch. */
  updateTokenByNodeId?: Maybe<UpdateTokenPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId?: Maybe<UpdateUserPayload>;
  updateUserPassword?: Maybe<UpdateUserPasswordPayload>;
  /** Updates a single `UserProfile` using a unique key and a patch. */
  updateUserProfile?: Maybe<UpdateUserProfilePayload>;
  /** Updates a single `UserProfile` using a unique key and a patch. */
  updateUserProfileByEmail?: Maybe<UpdateUserProfilePayload>;
  /** Updates a single `UserProfile` using a unique key and a patch. */
  updateUserProfileByMobile?: Maybe<UpdateUserProfilePayload>;
  /** Updates a single `UserProfile` using its globally unique id and a patch. */
  updateUserProfileByNodeId?: Maybe<UpdateUserProfilePayload>;
  /** Updates a single `UserProfile` using a unique key and a patch. */
  updateUserProfileByUserId?: Maybe<UpdateUserProfilePayload>;
  verifyEmail?: Maybe<VerifyEmailPayload>;
  verifyMobile?: Maybe<VerifyMobilePayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminCreateSubscriptionArgs = {
  input: AdminSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationApplyTokenArgs = {
  input: ApplyTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthenticateUserArgs = {
  input: AuthenticateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthenticateUserOtpArgs = {
  input: AuthenticateUserOtpInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInteractionArgs = {
  input: CreateInteractionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInteractionVersionArgs = {
  input: CreateInteractionVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNoteVersionArgs = {
  input: CreateNoteVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProgramArgs = {
  input: CreateProgramInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProgramVersionArgs = {
  input: CreateProgramVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStateArgs = {
  input: CreateStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTokenArgs = {
  input: CreateTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTypeormMetadatumArgs = {
  input: CreateTypeormMetadatumInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserProfileArgs = {
  input: CreateUserProfileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventByNodeIdArgs = {
  input: DeleteEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInteractionArgs = {
  input: DeleteInteractionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInteractionByNodeIdArgs = {
  input: DeleteInteractionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInteractionVersionArgs = {
  input: DeleteInteractionVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInteractionVersionByNodeIdArgs = {
  input: DeleteInteractionVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipArgs = {
  input: DeleteMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipByNodeIdArgs = {
  input: DeleteMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteByNodeIdArgs = {
  input: DeleteNoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteVersionArgs = {
  input: DeleteNoteVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteVersionByNodeIdArgs = {
  input: DeleteNoteVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationByNodeIdArgs = {
  input: DeleteOrganizationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationByUrlArgs = {
  input: DeleteOrganizationByUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramArgs = {
  input: DeleteProgramInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramByNodeIdArgs = {
  input: DeleteProgramByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramBySlugAndOrgIdArgs = {
  input: DeleteProgramBySlugAndOrgIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramVersionArgs = {
  input: DeleteProgramVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramVersionByNodeIdArgs = {
  input: DeleteProgramVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStateArgs = {
  input: DeleteStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStateByNodeIdArgs = {
  input: DeleteStateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStateByUserIdAndProgramIdAndInteractionIdArgs = {
  input: DeleteStateByUserIdAndProgramIdAndInteractionIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSubscriptionArgs = {
  input: DeleteSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSubscriptionByNodeIdArgs = {
  input: DeleteSubscriptionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSubscriptionByUserIdAndProgramIdArgs = {
  input: DeleteSubscriptionByUserIdAndProgramIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTokenArgs = {
  input: DeleteTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTokenByNodeIdArgs = {
  input: DeleteTokenByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByNodeIdArgs = {
  input: DeleteUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserProfileArgs = {
  input: DeleteUserProfileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserProfileByEmailArgs = {
  input: DeleteUserProfileByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserProfileByMobileArgs = {
  input: DeleteUserProfileByMobileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserProfileByNodeIdArgs = {
  input: DeleteUserProfileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserProfileByUserIdArgs = {
  input: DeleteUserProfileByUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDisableTokenArgs = {
  input: DisableTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateTokenArgs = {
  input: GenerateTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishInteractionArgs = {
  input: PublishInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishNoteArgs = {
  input: PublishInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishProgramArgs = {
  input: PublishInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestOtpArgs = {
  input: RequestOtpInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResubscribeArgs = {
  input: ResubscribeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetEmailArgs = {
  input: SetEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetFirstNameArgs = {
  input: SetFirstNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetMobileArgs = {
  input: SetMobileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStartResetPasswordArgs = {
  input: StartResetPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventByNodeIdArgs = {
  input: UpdateEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInteractionArgs = {
  input: UpdateInteractionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInteractionByNodeIdArgs = {
  input: UpdateInteractionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInteractionVersionArgs = {
  input: UpdateInteractionVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInteractionVersionByNodeIdArgs = {
  input: UpdateInteractionVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipByNodeIdArgs = {
  input: UpdateMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteByNodeIdArgs = {
  input: UpdateNoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteVersionArgs = {
  input: UpdateNoteVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteVersionByNodeIdArgs = {
  input: UpdateNoteVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationByNodeIdArgs = {
  input: UpdateOrganizationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationByUrlArgs = {
  input: UpdateOrganizationByUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramArgs = {
  input: UpdateProgramInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramByNodeIdArgs = {
  input: UpdateProgramByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramBySlugAndOrgIdArgs = {
  input: UpdateProgramBySlugAndOrgIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramVersionArgs = {
  input: UpdateProgramVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramVersionByNodeIdArgs = {
  input: UpdateProgramVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStateArgs = {
  input: UpdateStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStateByNodeIdArgs = {
  input: UpdateStateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStateByUserIdAndProgramIdAndInteractionIdArgs = {
  input: UpdateStateByUserIdAndProgramIdAndInteractionIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubscriptionByNodeIdArgs = {
  input: UpdateSubscriptionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubscriptionByUserIdAndProgramIdArgs = {
  input: UpdateSubscriptionByUserIdAndProgramIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTokenArgs = {
  input: UpdateTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTokenByNodeIdArgs = {
  input: UpdateTokenByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserProfileByEmailArgs = {
  input: UpdateUserProfileByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserProfileByMobileArgs = {
  input: UpdateUserProfileByMobileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserProfileByNodeIdArgs = {
  input: UpdateUserProfileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserProfileByUserIdArgs = {
  input: UpdateUserProfileByUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVerifyMobileArgs = {
  input: VerifyMobileInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type Note = Node & {
  __typename?: 'Note';
  access: NoteAccessEnum;
  atVersion?: Maybe<Note>;
  branch?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `NoteVersion`. */
  noteVersions: NoteVersionsConnection;
  /** Reads a single `Organization` that is related to this `Note`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: NoteStateEnum;
  subtype?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Token`. */
  tokens: TokensConnection;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};


export type NoteAtVersionArgs = {
  input: AtVersionInput;
};


export type NoteNoteVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


export type NoteTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TokenCondition>;
  filter?: InputMaybe<TokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

export enum NoteAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against NoteAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type NoteAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NoteAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NoteAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NoteAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NoteAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NoteAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NoteAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NoteAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NoteAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NoteAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NoteAccessEnum>>;
};

/** A condition to be used against `Note` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type NoteCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<NoteAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<NoteStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Note` object types. All fields are combined with a logical ‘and.’ */
export type NoteFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<NoteAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NoteFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NoteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NoteFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<NoteStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Note` */
export type NoteInput = {
  access?: InputMaybe<NoteAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<NoteStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Note`. Fields that are set will be updated. */
export type NotePatch = {
  access?: InputMaybe<NoteAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<NoteStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum NoteStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against NoteStateEnum fields. All fields are combined with a logical ‘and.’ */
export type NoteStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NoteStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NoteStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NoteStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NoteStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NoteStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NoteStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NoteStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NoteStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NoteStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NoteStateEnum>>;
};

export type NoteVersion = Node & {
  __typename?: 'NoteVersion';
  access: NoteVersionAccessEnum;
  branch?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  editor?: Maybe<User>;
  editorId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Note` that is related to this `NoteVersion`. */
  note?: Maybe<Note>;
  noteId: Scalars['Int'];
  /** Reads a single `Organization` that is related to this `NoteVersion`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: NoteVersionStateEnum;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `NoteVersion`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export enum NoteVersionAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against NoteVersionAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type NoteVersionAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NoteVersionAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NoteVersionAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NoteVersionAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NoteVersionAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NoteVersionAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NoteVersionAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NoteVersionAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NoteVersionAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NoteVersionAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NoteVersionAccessEnum>>;
};

/**
 * A condition to be used against `NoteVersion` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type NoteVersionCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<NoteVersionAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `editorId` field. */
  editorId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `noteId` field. */
  noteId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<NoteVersionStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `NoteVersion` object types. All fields are combined with a logical ‘and.’ */
export type NoteVersionFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<NoteVersionAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NoteVersionFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `editorId` field. */
  editorId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NoteVersionFilter>;
  /** Filter by the object’s `noteId` field. */
  noteId?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NoteVersionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<NoteVersionStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `NoteVersion` */
export type NoteVersionInput = {
  access?: InputMaybe<NoteVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  noteId: Scalars['Int'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<NoteVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `NoteVersion`. Fields that are set will be updated. */
export type NoteVersionPatch = {
  access?: InputMaybe<NoteVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  noteId?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<NoteVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

export enum NoteVersionStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against NoteVersionStateEnum fields. All fields are combined with a logical ‘and.’ */
export type NoteVersionStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NoteVersionStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NoteVersionStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NoteVersionStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NoteVersionStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NoteVersionStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NoteVersionStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NoteVersionStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NoteVersionStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NoteVersionStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NoteVersionStateEnum>>;
};

/** A connection to a list of `NoteVersion` values. */
export type NoteVersionsConnection = {
  __typename?: 'NoteVersionsConnection';
  /** A list of edges which contains the `NoteVersion` and cursor to aid in pagination. */
  edges: Array<NoteVersionsEdge>;
  /** A list of `NoteVersion` objects. */
  nodes: Array<Maybe<NoteVersion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NoteVersion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `NoteVersion` edge in the connection. */
export type NoteVersionsEdge = {
  __typename?: 'NoteVersionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NoteVersion` at the end of the edge. */
  node?: Maybe<NoteVersion>;
};

/** Methods to use when ordering `NoteVersion`. */
export enum NoteVersionsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EditorIdAsc = 'EDITOR_ID_ASC',
  EditorIdDesc = 'EDITOR_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NoteIdAsc = 'NOTE_ID_ASC',
  NoteIdDesc = 'NOTE_ID_DESC',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

/** A connection to a list of `Note` values. */
export type NotesConnection = {
  __typename?: 'NotesConnection';
  /** A list of edges which contains the `Note` and cursor to aid in pagination. */
  edges: Array<NotesEdge>;
  /** A list of `Note` objects. */
  nodes: Array<Maybe<Note>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Note` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Note` edge in the connection. */
export type NotesEdge = {
  __typename?: 'NotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Note` at the end of the edge. */
  node?: Maybe<Note>;
};

/** Methods to use when ordering `Note`. */
export enum NotesOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Organization = Node & {
  __typename?: 'Organization';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  interactionVersionsByOrgId: InteractionVersionsConnection;
  /** Reads and enables pagination through a set of `Interaction`. */
  interactionsByOrgId: InteractionsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  membershipsByOrgId: MembershipsConnection;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `NoteVersion`. */
  noteVersionsByOrgId: NoteVersionsConnection;
  /** Reads and enables pagination through a set of `Note`. */
  notesByOrgId: NotesConnection;
  /** Reads a single `User` that is related to this `Organization`. */
  owner?: Maybe<User>;
  ownerId: Scalars['Int'];
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  programVersionsByOrgId: ProgramVersionsConnection;
  /** Reads and enables pagination through a set of `Program`. */
  programsByOrgId: ProgramsConnection;
  /** Reads and enables pagination through a set of `Subscription`. */
  subscriptionsByOrgId: SubscriptionsConnection;
  /** Reads and enables pagination through a set of `Token`. */
  tokensByOrgId: TokensConnection;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  url: Scalars['String'];
};


export type OrganizationInteractionVersionsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


export type OrganizationInteractionsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionCondition>;
  filter?: InputMaybe<InteractionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};


export type OrganizationMembershipsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


export type OrganizationNoteVersionsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


export type OrganizationNotesByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteCondition>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};


export type OrganizationProgramVersionsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


export type OrganizationProgramsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramCondition>;
  filter?: InputMaybe<ProgramFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};


export type OrganizationSubscriptionsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SubscriptionCondition>;
  filter?: InputMaybe<SubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};


export type OrganizationTokensByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TokenCondition>;
  filter?: InputMaybe<TokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/**
 * A condition to be used against `Organization` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OrganizationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Organization` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `ownerId` field. */
  ownerId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Organization` */
export type OrganizationInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  ownerId: Scalars['Int'];
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url: Scalars['String'];
};

/** Represents an update to a `Organization`. Fields that are set will be updated. */
export type OrganizationPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Organization` values. */
export type OrganizationsConnection = {
  __typename?: 'OrganizationsConnection';
  /** A list of edges which contains the `Organization` and cursor to aid in pagination. */
  edges: Array<OrganizationsEdge>;
  /** A list of `Organization` objects. */
  nodes: Array<Maybe<Organization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Organization` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Organization` edge in the connection. */
export type OrganizationsEdge = {
  __typename?: 'OrganizationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Organization` at the end of the edge. */
  node?: Maybe<Organization>;
};

/** Methods to use when ordering `Organization`. */
export enum OrganizationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OwnerIdAsc = 'OWNER_ID_ASC',
  OwnerIdDesc = 'OWNER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type ParticipantProgramStatesInput = {
  programId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ParticipantProgramStatesPayload = {
  __typename?: 'ParticipantProgramStatesPayload';
  states: Array<State>;
};

export type ParticipantsProgramStatesInput = {
  programId: Scalars['Int'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ParticipantsProgramStatesPayload = {
  __typename?: 'ParticipantsProgramStatesPayload';
  states: Array<Scalars['JSON']>;
};

export type Program = Node & {
  __typename?: 'Program';
  access: ProgramAccessEnum;
  atVersion?: Maybe<Program>;
  branch?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `Program`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  programVersions: ProgramVersionsConnection;
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: ProgramStateEnum;
  /** Reads and enables pagination through a set of `State`. */
  states: StatesConnection;
  /** Reads and enables pagination through a set of `Subscription`. */
  subscriptions: SubscriptionsConnection;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Program`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};


export type ProgramAtVersionArgs = {
  input: AtVersionInput;
};


export type ProgramProgramVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


export type ProgramStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};


export type ProgramSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SubscriptionCondition>;
  filter?: InputMaybe<SubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};

export enum ProgramAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against ProgramAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type ProgramAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProgramAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProgramAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProgramAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProgramAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProgramAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProgramAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProgramAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProgramAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProgramAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProgramAccessEnum>>;
};

/** A condition to be used against `Program` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProgramCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<ProgramAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<ProgramStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Program` object types. All fields are combined with a logical ‘and.’ */
export type ProgramFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<ProgramAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProgramFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProgramFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProgramFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<ProgramStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Program` */
export type ProgramInput = {
  access?: InputMaybe<ProgramAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProgramStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Program`. Fields that are set will be updated. */
export type ProgramPatch = {
  access?: InputMaybe<ProgramAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProgramStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum ProgramStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against ProgramStateEnum fields. All fields are combined with a logical ‘and.’ */
export type ProgramStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProgramStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProgramStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProgramStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProgramStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProgramStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProgramStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProgramStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProgramStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProgramStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProgramStateEnum>>;
};

export type ProgramSubscribedParticipantsPayload = {
  __typename?: 'ProgramSubscribedParticipantsPayload';
  subscriptions: Array<Subscription>;
};

export type ProgramSubscribedParticipantsStatesInput = {
  orgId: Scalars['Int'];
  programId?: InputMaybe<Scalars['Int']>;
};

export type ProgramVersion = Node & {
  __typename?: 'ProgramVersion';
  access: ProgramVersionAccessEnum;
  branch?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  editor?: Maybe<User>;
  editorId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `ProgramVersion`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  /** Reads a single `Program` that is related to this `ProgramVersion`. */
  program?: Maybe<Program>;
  programId: Scalars['Int'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: ProgramVersionStateEnum;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export enum ProgramVersionAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against ProgramVersionAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type ProgramVersionAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProgramVersionAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProgramVersionAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProgramVersionAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProgramVersionAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProgramVersionAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProgramVersionAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProgramVersionAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProgramVersionAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProgramVersionAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProgramVersionAccessEnum>>;
};

/**
 * A condition to be used against `ProgramVersion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProgramVersionCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<ProgramVersionAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `editorId` field. */
  editorId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `programId` field. */
  programId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<ProgramVersionStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `ProgramVersion` object types. All fields are combined with a logical ‘and.’ */
export type ProgramVersionFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<ProgramVersionAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProgramVersionFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `editorId` field. */
  editorId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProgramVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProgramVersionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `programId` field. */
  programId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<ProgramVersionStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ProgramVersion` */
export type ProgramVersionInput = {
  access?: InputMaybe<ProgramVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId: Scalars['Int'];
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProgramVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `ProgramVersion`. Fields that are set will be updated. */
export type ProgramVersionPatch = {
  access?: InputMaybe<ProgramVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProgramVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

export enum ProgramVersionStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against ProgramVersionStateEnum fields. All fields are combined with a logical ‘and.’ */
export type ProgramVersionStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProgramVersionStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProgramVersionStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProgramVersionStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProgramVersionStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProgramVersionStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProgramVersionStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProgramVersionStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProgramVersionStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProgramVersionStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProgramVersionStateEnum>>;
};

/** A connection to a list of `ProgramVersion` values. */
export type ProgramVersionsConnection = {
  __typename?: 'ProgramVersionsConnection';
  /** A list of edges which contains the `ProgramVersion` and cursor to aid in pagination. */
  edges: Array<ProgramVersionsEdge>;
  /** A list of `ProgramVersion` objects. */
  nodes: Array<Maybe<ProgramVersion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProgramVersion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProgramVersion` edge in the connection. */
export type ProgramVersionsEdge = {
  __typename?: 'ProgramVersionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProgramVersion` at the end of the edge. */
  node?: Maybe<ProgramVersion>;
};

/** Methods to use when ordering `ProgramVersion`. */
export enum ProgramVersionsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EditorIdAsc = 'EDITOR_ID_ASC',
  EditorIdDesc = 'EDITOR_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProgramIdAsc = 'PROGRAM_ID_ASC',
  ProgramIdDesc = 'PROGRAM_ID_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

export type ProgramWithPayloadInput = {
  organizationSlug: Scalars['String'];
  programSlug: Scalars['String'];
  sections?: InputMaybe<Array<Scalars['String']>>;
  versionId?: InputMaybe<Scalars['Int']>;
};

export type ProgramWithPayloadPayload = {
  __typename?: 'ProgramWithPayloadPayload';
  interactions: Array<Interaction>;
  program: Program;
};

/** A connection to a list of `Program` values. */
export type ProgramsConnection = {
  __typename?: 'ProgramsConnection';
  /** A list of edges which contains the `Program` and cursor to aid in pagination. */
  edges: Array<ProgramsEdge>;
  /** A list of `Program` objects. */
  nodes: Array<Maybe<Program>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Program` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Program` edge in the connection. */
export type ProgramsEdge = {
  __typename?: 'ProgramsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Program` at the end of the edge. */
  node?: Maybe<Program>;
};

/** Methods to use when ordering `Program`. */
export enum ProgramsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type PublicProgram = {
  __typename?: 'PublicProgram';
  config?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type PublicProgramInput = {
  organizationSlug: Scalars['String'];
  slug: Scalars['String'];
};

export type PublishInput = {
  id: Scalars['Int'];
  state?: InputMaybe<PublishStateEnum>;
  versionId?: InputMaybe<Scalars['Int']>;
};

export type PublishInteractionPayload = {
  __typename?: 'PublishInteractionPayload';
  interaction?: Maybe<Interaction>;
  interactionVersion?: Maybe<InteractionVersion>;
};

export type PublishNotePayload = {
  __typename?: 'PublishNotePayload';
  note?: Maybe<Note>;
  noteVersion?: Maybe<NoteVersion>;
};

export type PublishProgramPayload = {
  __typename?: 'PublishProgramPayload';
  program?: Maybe<Program>;
  programVersion?: Maybe<ProgramVersion>;
};

export enum PublishStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

export type PublishedNoteInput = {
  id: Scalars['Int'];
};

export type PublishedProgramInput = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  canUpdateInteraction?: Maybe<Scalars['Boolean']>;
  canUpdateNote?: Maybe<Scalars['Boolean']>;
  canUpdateProgram?: Maybe<Scalars['Boolean']>;
  currentUserId?: Maybe<Scalars['Int']>;
  event?: Maybe<Event>;
  /** Reads a single `Event` using its globally unique `ID`. */
  eventByNodeId?: Maybe<Event>;
  /** Reads and enables pagination through a set of `Event`. */
  events?: Maybe<EventsConnection>;
  interaction?: Maybe<Interaction>;
  /** Reads a single `Interaction` using its globally unique `ID`. */
  interactionByNodeId?: Maybe<Interaction>;
  interactionCompletionCounts?: Maybe<InteractionCompletionCountsPayload>;
  interactionVersion?: Maybe<InteractionVersion>;
  /** Reads a single `InteractionVersion` using its globally unique `ID`. */
  interactionVersionByNodeId?: Maybe<InteractionVersion>;
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  interactionVersions?: Maybe<InteractionVersionsConnection>;
  /** Reads and enables pagination through a set of `Interaction`. */
  interactions?: Maybe<InteractionsConnection>;
  isMember?: Maybe<Scalars['Boolean']>;
  membership?: Maybe<Membership>;
  /** Reads a single `Membership` using its globally unique `ID`. */
  membershipByNodeId?: Maybe<Membership>;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships?: Maybe<MembershipsConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  note?: Maybe<Note>;
  /** Reads a single `Note` using its globally unique `ID`. */
  noteByNodeId?: Maybe<Note>;
  noteVersion?: Maybe<NoteVersion>;
  /** Reads a single `NoteVersion` using its globally unique `ID`. */
  noteVersionByNodeId?: Maybe<NoteVersion>;
  /** Reads and enables pagination through a set of `NoteVersion`. */
  noteVersions?: Maybe<NoteVersionsConnection>;
  /** Reads and enables pagination through a set of `Note`. */
  notes?: Maybe<NotesConnection>;
  organization?: Maybe<Organization>;
  /** Reads a single `Organization` using its globally unique `ID`. */
  organizationByNodeId?: Maybe<Organization>;
  organizationByUrl?: Maybe<Organization>;
  /** Reads and enables pagination through a set of `Organization`. */
  organizations?: Maybe<OrganizationsConnection>;
  participantProgramStates?: Maybe<ParticipantProgramStatesPayload>;
  participantsProgramStates?: Maybe<ParticipantsProgramStatesPayload>;
  program?: Maybe<Program>;
  /** Reads a single `Program` using its globally unique `ID`. */
  programByNodeId?: Maybe<Program>;
  programBySlugAndOrgId?: Maybe<Program>;
  programSubscribedParticipants?: Maybe<ProgramSubscribedParticipantsPayload>;
  programVersion?: Maybe<ProgramVersion>;
  /** Reads a single `ProgramVersion` using its globally unique `ID`. */
  programVersionByNodeId?: Maybe<ProgramVersion>;
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  programVersions?: Maybe<ProgramVersionsConnection>;
  programWithPayload?: Maybe<ProgramWithPayloadPayload>;
  /** Reads and enables pagination through a set of `Program`. */
  programs?: Maybe<ProgramsConnection>;
  publicProgram?: Maybe<PublicProgram>;
  publishedNote?: Maybe<Note>;
  publishedProgram?: Maybe<Program>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  registerDetails?: Maybe<RegisterDetailsPayload>;
  state?: Maybe<State>;
  /** Reads a single `State` using its globally unique `ID`. */
  stateByNodeId?: Maybe<State>;
  stateByUserIdAndProgramIdAndInteractionId?: Maybe<State>;
  /** Reads and enables pagination through a set of `State`. */
  states?: Maybe<StatesConnection>;
  subscribedParticipants?: Maybe<SubscribedParticipantsPayload>;
  subscribedPrograms?: Maybe<SubscribedProgramsPayload>;
  subscription?: Maybe<Subscription>;
  /** Reads a single `Subscription` using its globally unique `ID`. */
  subscriptionByNodeId?: Maybe<Subscription>;
  subscriptionByUserIdAndProgramId?: Maybe<Subscription>;
  /** Reads and enables pagination through a set of `Subscription`. */
  subscriptions?: Maybe<SubscriptionsConnection>;
  token?: Maybe<Token>;
  /** Reads a single `Token` using its globally unique `ID`. */
  tokenByNodeId?: Maybe<Token>;
  /** Reads and enables pagination through a set of `Token`. */
  tokens?: Maybe<TokensConnection>;
  /** Reads and enables pagination through a set of `TypeormMetadatum`. */
  typeormMetadata?: Maybe<TypeormMetadataConnection>;
  user?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  userProfile?: Maybe<UserProfile>;
  userProfileByEmail?: Maybe<UserProfile>;
  userProfileByMobile?: Maybe<UserProfile>;
  /** Reads a single `UserProfile` using its globally unique `ID`. */
  userProfileByNodeId?: Maybe<UserProfile>;
  userProfileByUserId?: Maybe<UserProfile>;
  /** Reads and enables pagination through a set of `UserProfile`. */
  userProfiles?: Maybe<UserProfilesConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  validateToken?: Maybe<Token>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCanUpdateInteractionArgs = {
  sourceId?: InputMaybe<Scalars['BigInt']>;
  subjectUserId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCanUpdateNoteArgs = {
  sourceNoteId?: InputMaybe<Scalars['BigInt']>;
  subjectUserId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCanUpdateProgramArgs = {
  sourceId?: InputMaybe<Scalars['BigInt']>;
  subjectUserId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEventArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EventCondition>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionCompletionCountsArgs = {
  input: InteractionCompletionInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionVersionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionVersionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionCondition>;
  filter?: InputMaybe<InteractionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIsMemberArgs = {
  orgId?: InputMaybe<Scalars['BigInt']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteVersionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteVersionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteCondition>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationByUrlArgs = {
  url: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCondition>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryParticipantProgramStatesArgs = {
  input: ParticipantProgramStatesInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryParticipantsProgramStatesArgs = {
  input: ParticipantsProgramStatesInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramBySlugAndOrgIdArgs = {
  orgId: Scalars['Int'];
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramSubscribedParticipantsArgs = {
  input: ProgramSubscribedParticipantsStatesInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramVersionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramVersionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramWithPayloadArgs = {
  input: ProgramWithPayloadInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramCondition>;
  filter?: InputMaybe<ProgramFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPublicProgramArgs = {
  input: PublicProgramInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryPublishedNoteArgs = {
  input: PublishedNoteInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryPublishedProgramArgs = {
  input: PublishedProgramInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryRegisterDetailsArgs = {
  input: RegisterDetailsInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryStateArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStateByUserIdAndProgramIdAndInteractionIdArgs = {
  interactionId: Scalars['Int'];
  programId: Scalars['Int'];
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscribedParticipantsArgs = {
  input: SubscribedParticipantsStatesInput;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionByUserIdAndProgramIdArgs = {
  programId: Scalars['Int'];
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SubscriptionCondition>;
  filter?: InputMaybe<SubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTokenArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTokenByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TokenCondition>;
  filter?: InputMaybe<TokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTypeormMetadataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TypeormMetadatumCondition>;
  filter?: InputMaybe<TypeormMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TypeormMetadataOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileByEmailArgs = {
  email: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileByMobileArgs = {
  mobile: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileByUserIdArgs = {
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserProfileCondition>;
  filter?: InputMaybe<UserProfileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserProfilesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryValidateTokenArgs = {
  id: Scalars['Int'];
  key: Scalars['String'];
};

export type RefreshTokenInput = {
  refresh: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  jwt?: Maybe<Scalars['String']>;
  refresh?: Maybe<Scalars['String']>;
};

export type RegisterDetailsInput = {
  orgId: Scalars['Int'];
  therapistId?: InputMaybe<Scalars['Int']>;
};

export type RegisterDetailsPayload = {
  __typename?: 'RegisterDetailsPayload';
  orgName?: Maybe<Scalars['String']>;
  therapistAvatar?: Maybe<Scalars['String']>;
  therapistName?: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  orgId?: InputMaybe<Scalars['Int']>;
  password: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  jwt?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  refresh?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type RequestOtpInput = {
  consent: Scalars['Boolean'];
  domain: Scalars['String'];
  mobile: Scalars['String'];
};

export type RequestOtpPayload = {
  __typename?: 'RequestOTPPayload';
  id: Scalars['String'];
  query?: Maybe<Query>;
};

export type ResetPasswordInput = {
  id: Scalars['String'];
  key: Scalars['String'];
  password: Scalars['String'];
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  id: Scalars['String'];
  jwt?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<Maybe<Program>>>;
  query?: Maybe<Query>;
  refresh?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  user?: Maybe<User>;
  userProfile?: Maybe<UserProfile>;
};

export type ResubscribeInput = {
  orgId: Scalars['Int'];
  programId: Scalars['Int'];
  therapistId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type SetEmailInput = {
  domain: Scalars['String'];
  email: Scalars['String'];
};

export type SetEmailPayload = {
  __typename?: 'SetEmailPayload';
  jwt?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  userProfile?: Maybe<UserProfile>;
};

export type SetFirstNameInput = {
  avatar?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  orgId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SetFirstNamePayload = {
  __typename?: 'SetFirstNamePayload';
  jwt?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  userProfile?: Maybe<UserProfile>;
};

export type SetMobileInput = {
  domain: Scalars['String'];
  mobile: Scalars['String'];
};

export type SetMobilePayload = {
  __typename?: 'SetMobilePayload';
  id: Scalars['String'];
  jwt?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  userProfile?: Maybe<UserProfile>;
};

export type StartResetPasswordInput = {
  domain: Scalars['String'];
  email: Scalars['String'];
};

export type StartResetPasswordPayload = {
  __typename?: 'StartResetPasswordPayload';
  id: Scalars['String'];
  query?: Maybe<Query>;
};

export type State = Node & {
  __typename?: 'State';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads a single `Interaction` that is related to this `State`. */
  interaction?: Maybe<Interaction>;
  interactionId?: Maybe<Scalars['Int']>;
  meta: Scalars['JSON'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  payload: Scalars['JSON'];
  /** Reads a single `Program` that is related to this `State`. */
  program?: Maybe<Program>;
  programId?: Maybe<Scalars['Int']>;
  state: StateStateEnum;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `State`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/** A condition to be used against `State` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type StateCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `interactionId` field. */
  interactionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `programId` field. */
  programId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<StateStateEnum>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `State` object types. All fields are combined with a logical ‘and.’ */
export type StateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StateFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `interactionId` field. */
  interactionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StateFilter>>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `programId` field. */
  programId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StateStateEnumFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `State` */
export type StateInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  interactionId?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['JSON']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<StateStateEnum>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `State`. Fields that are set will be updated. */
export type StatePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  interactionId?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['JSON']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<StateStateEnum>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum StateStateEnum {
  Completed = 'COMPLETED',
  New = 'NEW',
  Skipped = 'SKIPPED',
  Started = 'STARTED'
}

/** A filter to be used against StateStateEnum fields. All fields are combined with a logical ‘and.’ */
export type StateStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<StateStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<StateStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<StateStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<StateStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<StateStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<StateStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<StateStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<StateStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<StateStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<StateStateEnum>>;
};

/** A connection to a list of `State` values. */
export type StatesConnection = {
  __typename?: 'StatesConnection';
  /** A list of edges which contains the `State` and cursor to aid in pagination. */
  edges: Array<StatesEdge>;
  /** A list of `State` objects. */
  nodes: Array<Maybe<State>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `State` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `State` edge in the connection. */
export type StatesEdge = {
  __typename?: 'StatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `State` at the end of the edge. */
  node?: Maybe<State>;
};

/** Methods to use when ordering `State`. */
export enum StatesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InteractionIdAsc = 'INTERACTION_ID_ASC',
  InteractionIdDesc = 'INTERACTION_ID_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  Natural = 'NATURAL',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProgramIdAsc = 'PROGRAM_ID_ASC',
  ProgramIdDesc = 'PROGRAM_ID_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

export type SubscribedParticipantsPayload = {
  __typename?: 'SubscribedParticipantsPayload';
  configs: Scalars['JSON'];
  subscriptions: Array<Subscription>;
};

export type SubscribedParticipantsStatesInput = {
  orgId: Scalars['Int'];
  therapistId?: InputMaybe<Scalars['Int']>;
};

export type SubscribedProgramsPayload = {
  __typename?: 'SubscribedProgramsPayload';
  interactions: Array<Interaction>;
  subscriptions: Array<Subscription>;
};

export type Subscription = Node & {
  __typename?: 'Subscription';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  meta: Scalars['JSON'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `Subscription`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  /** Reads a single `Program` that is related to this `Subscription`. */
  program?: Maybe<Program>;
  programId?: Maybe<Scalars['Int']>;
  state: SubscriptionStateEnum;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Subscription`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `Subscription` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SubscriptionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `programId` field. */
  programId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<SubscriptionStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Subscription` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SubscriptionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SubscriptionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `programId` field. */
  programId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<SubscriptionStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Subscription` */
export type SubscriptionInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['JSON']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<SubscriptionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Subscription`. Fields that are set will be updated. */
export type SubscriptionPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['JSON']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<SubscriptionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum SubscriptionStateEnum {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Disabled = 'DISABLED',
  Expired = 'EXPIRED'
}

/** A filter to be used against SubscriptionStateEnum fields. All fields are combined with a logical ‘and.’ */
export type SubscriptionStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SubscriptionStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SubscriptionStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SubscriptionStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SubscriptionStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SubscriptionStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SubscriptionStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SubscriptionStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SubscriptionStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SubscriptionStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SubscriptionStateEnum>>;
};

/** A connection to a list of `Subscription` values. */
export type SubscriptionsConnection = {
  __typename?: 'SubscriptionsConnection';
  /** A list of edges which contains the `Subscription` and cursor to aid in pagination. */
  edges: Array<SubscriptionsEdge>;
  /** A list of `Subscription` objects. */
  nodes: Array<Maybe<Subscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Subscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Subscription` edge in the connection. */
export type SubscriptionsEdge = {
  __typename?: 'SubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Subscription` at the end of the edge. */
  node?: Maybe<Subscription>;
};

/** Methods to use when ordering `Subscription`. */
export enum SubscriptionsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProgramIdAsc = 'PROGRAM_ID_ASC',
  ProgramIdDesc = 'PROGRAM_ID_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Token = Node & {
  __typename?: 'Token';
  createdAt: Scalars['Datetime'];
  details: Scalars['JSON'];
  /** Reads a single `User` that is related to this `Token`. */
  grantedBy?: Maybe<User>;
  grantedById?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  key: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Note` that is related to this `Token`. */
  note?: Maybe<Note>;
  noteId?: Maybe<Scalars['Int']>;
  /** Reads a single `Organization` that is related to this `Token`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  state: TokenStateEnum;
  type: TokenTypeEnum;
  updatedAt: Scalars['Datetime'];
};

/** A condition to be used against `Token` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TokenCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `details` field. */
  details?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `grantedById` field. */
  grantedById?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `noteId` field. */
  noteId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<TokenStateEnum>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<TokenTypeEnum>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Token` object types. All fields are combined with a logical ‘and.’ */
export type TokenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `details` field. */
  details?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `grantedById` field. */
  grantedById?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TokenFilter>;
  /** Filter by the object’s `noteId` field. */
  noteId?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<TokenStateEnumFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<TokenTypeEnumFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Token` */
export type TokenInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  details?: InputMaybe<Scalars['JSON']>;
  grantedById?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  key: Scalars['UUID'];
  noteId?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<TokenStateEnum>;
  type: TokenTypeEnum;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Token`. Fields that are set will be updated. */
export type TokenPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  details?: InputMaybe<Scalars['JSON']>;
  grantedById?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['UUID']>;
  noteId?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<TokenStateEnum>;
  type?: InputMaybe<TokenTypeEnum>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum TokenStateEnum {
  Accepted = 'ACCEPTED',
  Disabled = 'DISABLED',
  New = 'NEW'
}

/** A filter to be used against TokenStateEnum fields. All fields are combined with a logical ‘and.’ */
export type TokenStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<TokenStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<TokenStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<TokenStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<TokenStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<TokenStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<TokenStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<TokenStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<TokenStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<TokenStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<TokenStateEnum>>;
};

export enum TokenTypeEnum {
  Enrollment = 'ENROLLMENT',
  Membership = 'MEMBERSHIP',
  Note = 'NOTE',
  Organization = 'ORGANIZATION',
  Verification = 'VERIFICATION'
}

/** A filter to be used against TokenTypeEnum fields. All fields are combined with a logical ‘and.’ */
export type TokenTypeEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<TokenTypeEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<TokenTypeEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<TokenTypeEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<TokenTypeEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<TokenTypeEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<TokenTypeEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<TokenTypeEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<TokenTypeEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<TokenTypeEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<TokenTypeEnum>>;
};

/** A connection to a list of `Token` values. */
export type TokensConnection = {
  __typename?: 'TokensConnection';
  /** A list of edges which contains the `Token` and cursor to aid in pagination. */
  edges: Array<TokensEdge>;
  /** A list of `Token` objects. */
  nodes: Array<Maybe<Token>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Token` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Token` edge in the connection. */
export type TokensEdge = {
  __typename?: 'TokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Token` at the end of the edge. */
  node?: Maybe<Token>;
};

/** Methods to use when ordering `Token`. */
export enum TokensOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DetailsAsc = 'DETAILS_ASC',
  DetailsDesc = 'DETAILS_DESC',
  GrantedByIdAsc = 'GRANTED_BY_ID_ASC',
  GrantedByIdDesc = 'GRANTED_BY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  NoteIdAsc = 'NOTE_ID_ASC',
  NoteIdDesc = 'NOTE_ID_DESC',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A connection to a list of `TypeormMetadatum` values. */
export type TypeormMetadataConnection = {
  __typename?: 'TypeormMetadataConnection';
  /** A list of edges which contains the `TypeormMetadatum` and cursor to aid in pagination. */
  edges: Array<TypeormMetadataEdge>;
  /** A list of `TypeormMetadatum` objects. */
  nodes: Array<Maybe<TypeormMetadatum>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TypeormMetadatum` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TypeormMetadatum` edge in the connection. */
export type TypeormMetadataEdge = {
  __typename?: 'TypeormMetadataEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TypeormMetadatum` at the end of the edge. */
  node?: Maybe<TypeormMetadatum>;
};

/** Methods to use when ordering `TypeormMetadatum`. */
export enum TypeormMetadataOrderBy {
  DatabaseAsc = 'DATABASE_ASC',
  DatabaseDesc = 'DATABASE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  SchemaAsc = 'SCHEMA_ASC',
  SchemaDesc = 'SCHEMA_DESC',
  TableAsc = 'TABLE_ASC',
  TableDesc = 'TABLE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

export type TypeormMetadatum = {
  __typename?: 'TypeormMetadatum';
  database?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `TypeormMetadatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TypeormMetadatumCondition = {
  /** Checks for equality with the object’s `database` field. */
  database?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `schema` field. */
  schema?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `table` field. */
  table?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TypeormMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type TypeormMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TypeormMetadatumFilter>>;
  /** Filter by the object’s `database` field. */
  database?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TypeormMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TypeormMetadatumFilter>>;
  /** Filter by the object’s `schema` field. */
  schema?: InputMaybe<StringFilter>;
  /** Filter by the object’s `table` field. */
  table?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TypeormMetadatum` */
export type TypeormMetadatumInput = {
  database?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['String']>;
  table?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

/** All input for the `updateEventByNodeId` mutation. */
export type UpdateEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Event` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Event` being updated. */
  patch: EventPatch;
};

/** All input for the `updateEvent` mutation. */
export type UpdateEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Event` being updated. */
  patch: EventPatch;
};

/** The output of our update `Event` mutation. */
export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was updated by this mutation. */
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Event` mutation. */
export type UpdateEventPayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the `updateInteractionByNodeId` mutation. */
export type UpdateInteractionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Interaction` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Interaction` being updated. */
  patch: InteractionPatch;
};

/** All input for the `updateInteraction` mutation. */
export type UpdateInteractionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Interaction` being updated. */
  patch: InteractionPatch;
};

/** The output of our update `Interaction` mutation. */
export type UpdateInteractionPayload = {
  __typename?: 'UpdateInteractionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Interaction` that was updated by this mutation. */
  interaction?: Maybe<Interaction>;
  /** An edge for our `Interaction`. May be used by Relay 1. */
  interactionEdge?: Maybe<InteractionsEdge>;
  /** Reads a single `Organization` that is related to this `Interaction`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Interaction`. */
  user?: Maybe<User>;
};


/** The output of our update `Interaction` mutation. */
export type UpdateInteractionPayloadInteractionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};

/** All input for the `updateInteractionVersionByNodeId` mutation. */
export type UpdateInteractionVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InteractionVersion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `InteractionVersion` being updated. */
  patch: InteractionVersionPatch;
};

/** All input for the `updateInteractionVersion` mutation. */
export type UpdateInteractionVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `InteractionVersion` being updated. */
  patch: InteractionVersionPatch;
};

/** The output of our update `InteractionVersion` mutation. */
export type UpdateInteractionVersionPayload = {
  __typename?: 'UpdateInteractionVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Interaction` that is related to this `InteractionVersion`. */
  interaction?: Maybe<Interaction>;
  /** The `InteractionVersion` that was updated by this mutation. */
  interactionVersion?: Maybe<InteractionVersion>;
  /** An edge for our `InteractionVersion`. May be used by Relay 1. */
  interactionVersionEdge?: Maybe<InteractionVersionsEdge>;
  /** Reads a single `Organization` that is related to this `InteractionVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  user?: Maybe<User>;
};


/** The output of our update `InteractionVersion` mutation. */
export type UpdateInteractionVersionPayloadInteractionVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};

/** All input for the `updateMembershipByNodeId` mutation. */
export type UpdateMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Membership` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
};

/** All input for the `updateMembership` mutation. */
export type UpdateMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
};

/** The output of our update `Membership` mutation. */
export type UpdateMembershipPayload = {
  __typename?: 'UpdateMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Membership` that was updated by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Reads a single `Organization` that is related to this `Membership`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our update `Membership` mutation. */
export type UpdateMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** All input for the `updateNoteByNodeId` mutation. */
export type UpdateNoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Note` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Note` being updated. */
  patch: NotePatch;
};

/** All input for the `updateNote` mutation. */
export type UpdateNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Note` being updated. */
  patch: NotePatch;
};

/** The output of our update `Note` mutation. */
export type UpdateNotePayload = {
  __typename?: 'UpdateNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Note` that was updated by this mutation. */
  note?: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge?: Maybe<NotesEdge>;
  /** Reads a single `Organization` that is related to this `Note`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
};


/** The output of our update `Note` mutation. */
export type UpdateNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the `updateNoteVersionByNodeId` mutation. */
export type UpdateNoteVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NoteVersion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `NoteVersion` being updated. */
  patch: NoteVersionPatch;
};

/** All input for the `updateNoteVersion` mutation. */
export type UpdateNoteVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `NoteVersion` being updated. */
  patch: NoteVersionPatch;
};

/** The output of our update `NoteVersion` mutation. */
export type UpdateNoteVersionPayload = {
  __typename?: 'UpdateNoteVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Note` that is related to this `NoteVersion`. */
  note?: Maybe<Note>;
  /** The `NoteVersion` that was updated by this mutation. */
  noteVersion?: Maybe<NoteVersion>;
  /** An edge for our `NoteVersion`. May be used by Relay 1. */
  noteVersionEdge?: Maybe<NoteVersionsEdge>;
  /** Reads a single `Organization` that is related to this `NoteVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  user?: Maybe<User>;
};


/** The output of our update `NoteVersion` mutation. */
export type UpdateNoteVersionPayloadNoteVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};

/** All input for the `updateOrganizationByNodeId` mutation. */
export type UpdateOrganizationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Organization` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** All input for the `updateOrganizationByUrl` mutation. */
export type UpdateOrganizationByUrlInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
  url: Scalars['String'];
};

/** All input for the `updateOrganization` mutation. */
export type UpdateOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** The output of our update `Organization` mutation. */
export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Organization` that was updated by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Reads a single `User` that is related to this `Organization`. */
  owner?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Organization` mutation. */
export type UpdateOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `updateProgramByNodeId` mutation. */
export type UpdateProgramByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Program` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Program` being updated. */
  patch: ProgramPatch;
};

/** All input for the `updateProgramBySlugAndOrgId` mutation. */
export type UpdateProgramBySlugAndOrgIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orgId: Scalars['Int'];
  /** An object where the defined keys will be set on the `Program` being updated. */
  patch: ProgramPatch;
  slug: Scalars['String'];
};

/** All input for the `updateProgram` mutation. */
export type UpdateProgramInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Program` being updated. */
  patch: ProgramPatch;
};

/** The output of our update `Program` mutation. */
export type UpdateProgramPayload = {
  __typename?: 'UpdateProgramPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Organization` that is related to this `Program`. */
  org?: Maybe<Organization>;
  /** The `Program` that was updated by this mutation. */
  program?: Maybe<Program>;
  /** An edge for our `Program`. May be used by Relay 1. */
  programEdge?: Maybe<ProgramsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Program`. */
  user?: Maybe<User>;
};


/** The output of our update `Program` mutation. */
export type UpdateProgramPayloadProgramEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};

/** All input for the `updateProgramVersionByNodeId` mutation. */
export type UpdateProgramVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProgramVersion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProgramVersion` being updated. */
  patch: ProgramVersionPatch;
};

/** All input for the `updateProgramVersion` mutation. */
export type UpdateProgramVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ProgramVersion` being updated. */
  patch: ProgramVersionPatch;
};

/** The output of our update `ProgramVersion` mutation. */
export type UpdateProgramVersionPayload = {
  __typename?: 'UpdateProgramVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Organization` that is related to this `ProgramVersion`. */
  org?: Maybe<Organization>;
  /** Reads a single `Program` that is related to this `ProgramVersion`. */
  program?: Maybe<Program>;
  /** The `ProgramVersion` that was updated by this mutation. */
  programVersion?: Maybe<ProgramVersion>;
  /** An edge for our `ProgramVersion`. May be used by Relay 1. */
  programVersionEdge?: Maybe<ProgramVersionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  user?: Maybe<User>;
};


/** The output of our update `ProgramVersion` mutation. */
export type UpdateProgramVersionPayloadProgramVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};

/** All input for the `updateStateByNodeId` mutation. */
export type UpdateStateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `State` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `State` being updated. */
  patch: StatePatch;
};

/** All input for the `updateStateByUserIdAndProgramIdAndInteractionId` mutation. */
export type UpdateStateByUserIdAndProgramIdAndInteractionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  interactionId: Scalars['Int'];
  /** An object where the defined keys will be set on the `State` being updated. */
  patch: StatePatch;
  programId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** All input for the `updateState` mutation. */
export type UpdateStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `State` being updated. */
  patch: StatePatch;
};

/** The output of our update `State` mutation. */
export type UpdateStatePayload = {
  __typename?: 'UpdateStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Interaction` that is related to this `State`. */
  interaction?: Maybe<Interaction>;
  /** Reads a single `Program` that is related to this `State`. */
  program?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was updated by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
  /** Reads a single `User` that is related to this `State`. */
  user?: Maybe<User>;
};


/** The output of our update `State` mutation. */
export type UpdateStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the `updateSubscriptionByNodeId` mutation. */
export type UpdateSubscriptionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Subscription` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Subscription` being updated. */
  patch: SubscriptionPatch;
};

/** All input for the `updateSubscriptionByUserIdAndProgramId` mutation. */
export type UpdateSubscriptionByUserIdAndProgramIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Subscription` being updated. */
  patch: SubscriptionPatch;
  programId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** All input for the `updateSubscription` mutation. */
export type UpdateSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Subscription` being updated. */
  patch: SubscriptionPatch;
};

/** The output of our update `Subscription` mutation. */
export type UpdateSubscriptionPayload = {
  __typename?: 'UpdateSubscriptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Organization` that is related to this `Subscription`. */
  org?: Maybe<Organization>;
  /** Reads a single `Program` that is related to this `Subscription`. */
  program?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Subscription` that was updated by this mutation. */
  subscription?: Maybe<Subscription>;
  /** An edge for our `Subscription`. May be used by Relay 1. */
  subscriptionEdge?: Maybe<SubscriptionsEdge>;
  /** Reads a single `User` that is related to this `Subscription`. */
  user?: Maybe<User>;
};


/** The output of our update `Subscription` mutation. */
export type UpdateSubscriptionPayloadSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};

/** All input for the `updateTokenByNodeId` mutation. */
export type UpdateTokenByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Token` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Token` being updated. */
  patch: TokenPatch;
};

/** All input for the `updateToken` mutation. */
export type UpdateTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Token` being updated. */
  patch: TokenPatch;
};

/** The output of our update `Token` mutation. */
export type UpdateTokenPayload = {
  __typename?: 'UpdateTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Token`. */
  grantedBy?: Maybe<User>;
  /** Reads a single `Note` that is related to this `Token`. */
  note?: Maybe<Note>;
  /** Reads a single `Organization` that is related to this `Token`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Token` that was updated by this mutation. */
  token?: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge?: Maybe<TokensEdge>;
};


/** The output of our update `Token` mutation. */
export type UpdateTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

export type UpdateUserPasswordInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UpdateUserPasswordPayload = {
  __typename?: 'UpdateUserPasswordPayload';
  query?: Maybe<Query>;
  user?: Maybe<User>;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUserProfileByEmail` mutation. */
export type UpdateUserProfileByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** An object where the defined keys will be set on the `UserProfile` being updated. */
  patch: UserProfilePatch;
};

/** All input for the `updateUserProfileByMobile` mutation. */
export type UpdateUserProfileByMobileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  mobile: Scalars['String'];
  /** An object where the defined keys will be set on the `UserProfile` being updated. */
  patch: UserProfilePatch;
};

/** All input for the `updateUserProfileByNodeId` mutation. */
export type UpdateUserProfileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserProfile` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserProfile` being updated. */
  patch: UserProfilePatch;
};

/** All input for the `updateUserProfileByUserId` mutation. */
export type UpdateUserProfileByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserProfile` being updated. */
  patch: UserProfilePatch;
  userId: Scalars['Int'];
};

/** All input for the `updateUserProfile` mutation. */
export type UpdateUserProfileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UserProfile` being updated. */
  patch: UserProfilePatch;
};

/** The output of our update `UserProfile` mutation. */
export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserProfile`. */
  user?: Maybe<User>;
  /** The `UserProfile` that was updated by this mutation. */
  userProfile?: Maybe<UserProfile>;
  /** An edge for our `UserProfile`. May be used by Relay 1. */
  userProfileEdge?: Maybe<UserProfilesEdge>;
};


/** The output of our update `UserProfile` mutation. */
export type UpdateUserProfilePayloadUserProfileEdgeArgs = {
  orderBy?: InputMaybe<Array<UserProfilesOrderBy>>;
};

export type User = Node & {
  __typename?: 'User';
  createdAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  editedInteractionVersions: InteractionVersionsConnection;
  /** Reads and enables pagination through a set of `NoteVersion`. */
  editedNoteVersions: NoteVersionsConnection;
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  editedProgramVersions: ProgramVersionsConnection;
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  interactionVersions: InteractionVersionsConnection;
  /** Reads and enables pagination through a set of `Interaction`. */
  interactions: InteractionsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships: MembershipsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `NoteVersion`. */
  noteVersions: NoteVersionsConnection;
  /** Reads and enables pagination through a set of `Note`. */
  notes: NotesConnection;
  /** Reads and enables pagination through a set of `Organization`. */
  organizationsByOwnerId: OrganizationsConnection;
  password: Scalars['String'];
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  programVersions: ProgramVersionsConnection;
  /** Reads and enables pagination through a set of `Program`. */
  programs: ProgramsConnection;
  /** Reads and enables pagination through a set of `State`. */
  states: StatesConnection;
  /** Reads and enables pagination through a set of `Subscription`. */
  subscriptions: SubscriptionsConnection;
  /** Reads and enables pagination through a set of `Token`. */
  tokensByGrantedById: TokensConnection;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `UserProfile` that is related to this `User`. */
  userProfile?: Maybe<UserProfile>;
  /**
   * Reads and enables pagination through a set of `UserProfile`.
   * @deprecated Please use userProfile instead
   */
  userProfiles: UserProfilesConnection;
  username: Scalars['String'];
};


export type UserEditedInteractionVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


export type UserEditedNoteVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


export type UserEditedProgramVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


export type UserInteractionVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


export type UserInteractionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionCondition>;
  filter?: InputMaybe<InteractionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};


export type UserMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


export type UserNoteVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


export type UserNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteCondition>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};


export type UserOrganizationsByOwnerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCondition>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};


export type UserProgramVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


export type UserProgramsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramCondition>;
  filter?: InputMaybe<ProgramFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};


export type UserStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};


export type UserSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SubscriptionCondition>;
  filter?: InputMaybe<SubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};


export type UserTokensByGrantedByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TokenCondition>;
  filter?: InputMaybe<TokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};


export type UserUserProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserProfileCondition>;
  filter?: InputMaybe<UserProfileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserProfilesOrderBy>>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `password` field. */
  password?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `password` field. */
  password?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  password: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  username: Scalars['String'];
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserProfile = Node & {
  __typename?: 'UserProfile';
  createdAt: Scalars['Datetime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  meta: Scalars['JSON'];
  mobile?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  payload: Scalars['JSON'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `UserProfile`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `UserProfile` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserProfileCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `mobile` field. */
  mobile?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `UserProfile` object types. All fields are combined with a logical ‘and.’ */
export type UserProfileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserProfileFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mobile` field. */
  mobile?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserProfileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserProfileFilter>>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `UserProfile` */
export type UserProfileInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['JSON']>;
  mobile?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `UserProfile`. Fields that are set will be updated. */
export type UserProfilePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['JSON']>;
  mobile?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `UserProfile` values. */
export type UserProfilesConnection = {
  __typename?: 'UserProfilesConnection';
  /** A list of edges which contains the `UserProfile` and cursor to aid in pagination. */
  edges: Array<UserProfilesEdge>;
  /** A list of `UserProfile` objects. */
  nodes: Array<Maybe<UserProfile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserProfile` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserProfile` edge in the connection. */
export type UserProfilesEdge = {
  __typename?: 'UserProfilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserProfile` at the end of the edge. */
  node?: Maybe<UserProfile>;
};

/** Methods to use when ordering `UserProfile`. */
export enum UserProfilesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  MobileAsc = 'MOBILE_ASC',
  MobileDesc = 'MOBILE_DESC',
  Natural = 'NATURAL',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PasswordAsc = 'PASSWORD_ASC',
  PasswordDesc = 'PASSWORD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export type VerifyEmailInput = {
  id: Scalars['String'];
  key: Scalars['String'];
};

export type VerifyEmailPayload = {
  __typename?: 'VerifyEmailPayload';
  jwt?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  userProfile?: Maybe<UserProfile>;
};

export type VerifyMobileInput = {
  id: Scalars['String'];
  key: Scalars['String'];
};

export type VerifyMobilePayload = {
  __typename?: 'VerifyMobilePayload';
  jwt?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  userProfile?: Maybe<UserProfile>;
};

export type ProgramQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProgramQuery = { __typename?: 'Query', program?: { __typename: 'Program', id: number, userId?: number | null, orgId?: number | null, type: string, name: string, slug?: string | null, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null };

export type ProgramWithPayloadQueryVariables = Exact<{
  organizationSlug: Scalars['String'];
  programSlug: Scalars['String'];
  sections: Array<Scalars['String']> | Scalars['String'];
  versionId?: InputMaybe<Scalars['Int']>;
}>;


export type ProgramWithPayloadQuery = { __typename?: 'Query', programWithPayload?: { __typename?: 'ProgramWithPayloadPayload', program: { __typename?: 'Program', id: number, name: string, payload: any, orgId?: number | null }, interactions: Array<{ __typename?: 'Interaction', id: number, name: string, payload: any }> } | null };

export type PublicProgramQueryVariables = Exact<{
  organizationSlug: Scalars['String'];
  slug: Scalars['String'];
}>;


export type PublicProgramQuery = { __typename?: 'Query', publicProgram?: { __typename?: 'PublicProgram', id: number, name: string, slug: string, config?: any | null } | null };

export type StatesQueryVariables = Exact<{
  programId?: InputMaybe<Scalars['Int']>;
  userId: Scalars['Int'];
}>;


export type StatesQuery = { __typename?: 'Query', states?: { __typename?: 'StatesConnection', nodes: Array<{ __typename: 'State', id: number, userId: number, state: StateStateEnum, programId?: number | null, interactionId?: number | null, payload: any, meta: any, createdAt: any, updatedAt: any } | null> } | null };

export type CreateStateMutationVariables = Exact<{
  userId: Scalars['Int'];
  programId: Scalars['Int'];
  interactionId: Scalars['Int'];
  state?: InputMaybe<StateStateEnum>;
  payload?: InputMaybe<Scalars['JSON']>;
  meta?: InputMaybe<Scalars['JSON']>;
}>;


export type CreateStateMutation = { __typename?: 'Mutation', createState?: { __typename?: 'CreateStatePayload', state?: { __typename: 'State', id: number, userId: number, programId?: number | null, interactionId?: number | null, state: StateStateEnum, payload: any, meta: any, createdAt: any, updatedAt: any } | null } | null };

export type UpdateStateMutationVariables = Exact<{
  id: Scalars['Int'];
  state?: InputMaybe<StateStateEnum>;
  payload?: InputMaybe<Scalars['JSON']>;
  meta?: InputMaybe<Scalars['JSON']>;
}>;


export type UpdateStateMutation = { __typename?: 'Mutation', updateState?: { __typename?: 'UpdateStatePayload', state?: { __typename: 'State', id: number, userId: number, programId?: number | null, interactionId?: number | null, state: StateStateEnum, payload: any, meta: any, createdAt: any, updatedAt: any } | null } | null };

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent?: { __typename?: 'CreateEventPayload', event?: { __typename: 'Event', id: number, userId: number, programId: number, interactionId: number, type: string, subtype?: string | null, name: string, payload: any, meta: any, createdAt: any, updatedAt: any } | null } | null };

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent?: { __typename?: 'UpdateEventPayload', event?: { __typename: 'Event', id: number, userId: number, programId: number, interactionId: number, type: string, subtype?: string | null, name: string, payload: any, meta: any, createdAt: any, updatedAt: any } | null } | null };

export type EventsQueryVariables = Exact<{
  condition?: InputMaybe<EventCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EventsOrderBy> | EventsOrderBy>;
}>;


export type EventsQuery = { __typename?: 'Query', events?: { __typename?: 'EventsConnection', totalCount: number, nodes: Array<{ __typename?: 'Event', id: number, name: string, type: string, subtype?: string | null, userId: number, programId: number, interactionId: number, payload: any, meta: any, createdAt: any, updatedAt: any } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: any | null, endCursor?: any | null } } | null };

export type AuthenticateUserMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
}>;


export type AuthenticateUserMutation = { __typename?: 'Mutation', authenticateUser?: { __typename?: 'AuthenticateUserPayload', jwt?: string | null, refresh?: string | null, user?: { __typename: 'User', id: number, username: string } | null, subscriptions?: Array<{ __typename?: 'Subscription', id: number, orgId?: number | null, userId: number, programId?: number | null, type: string, subtype?: string | null, state: SubscriptionStateEnum, payload: any, meta: any, createdAt: any, updatedAt: any, program?: { __typename?: 'Program', id: number, slug?: string | null, name: string, config?: any | null } | null } | null> | null, programs?: Array<{ __typename?: 'Program', id: number, slug?: string | null } | null> | null, userProfile?: { __typename?: 'UserProfile', id: number, firstName?: string | null, email?: string | null } | null } | null };

export type RefreshTokenMutationVariables = Exact<{
  refresh: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken?: { __typename?: 'RefreshTokenPayload', jwt?: string | null, refresh?: string | null } | null };

export type SetEmailMutationVariables = Exact<{
  email: Scalars['String'];
  domain: Scalars['String'];
}>;


export type SetEmailMutation = { __typename?: 'Mutation', setEmail?: { __typename?: 'SetEmailPayload', jwt?: string | null, userProfile?: { __typename?: 'UserProfile', id: number, email?: string | null, payload: any } | null } | null };

export type VerifyEmailMutationVariables = Exact<{
  id: Scalars['String'];
  key: Scalars['String'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail?: { __typename?: 'VerifyEmailPayload', jwt?: string | null, userProfile?: { __typename?: 'UserProfile', id: number, email?: string | null, payload: any } | null } | null };

export type SetMobileMutationVariables = Exact<{
  mobile: Scalars['String'];
  domain: Scalars['String'];
}>;


export type SetMobileMutation = { __typename?: 'Mutation', setMobile?: { __typename?: 'SetMobilePayload', jwt?: string | null, id: string, userProfile?: { __typename?: 'UserProfile', id: number, mobile?: string | null, payload: any } | null } | null };

export type VerifyMobileMutationVariables = Exact<{
  id: Scalars['String'];
  key: Scalars['String'];
}>;


export type VerifyMobileMutation = { __typename?: 'Mutation', verifyMobile?: { __typename?: 'VerifyMobilePayload', jwt?: string | null, userProfile?: { __typename?: 'UserProfile', id: number, firstName?: string | null, mobile?: string | null, payload: any } | null } | null };

export type RequestOtpMutationVariables = Exact<{
  mobile: Scalars['String'];
  domain: Scalars['String'];
  consent: Scalars['Boolean'];
}>;


export type RequestOtpMutation = { __typename?: 'Mutation', requestOTP?: { __typename?: 'RequestOTPPayload', id: string } | null };

export type AuthenticateUserOtpMutationVariables = Exact<{
  id: Scalars['String'];
  otpKey: Scalars['String'];
  role: Scalars['String'];
}>;


export type AuthenticateUserOtpMutation = { __typename?: 'Mutation', authenticateUserOTP?: { __typename?: 'AuthenticateUserOTPPayload', jwt?: string | null, refresh?: string | null, userProfile?: { __typename?: 'UserProfile', id: number, mobile?: string | null, firstName?: string | null, payload: any } | null, user?: { __typename: 'User', id: number, username: string } | null, subscriptions?: Array<{ __typename?: 'Subscription', id: number, orgId?: number | null, userId: number, programId?: number | null, type: string, subtype?: string | null, state: SubscriptionStateEnum, payload: any, meta: any, createdAt: any, updatedAt: any, program?: { __typename?: 'Program', id: number, slug?: string | null, name: string, config?: any | null } | null } | null> | null, programs?: Array<{ __typename?: 'Program', id: number, slug?: string | null } | null> | null } | null };

export type SetFirstNameMutationVariables = Exact<{
  firstName: Scalars['String'];
}>;


export type SetFirstNameMutation = { __typename?: 'Mutation', setFirstName?: { __typename?: 'SetFirstNamePayload', jwt?: string | null, userProfile?: { __typename?: 'UserProfile', id: number, firstName?: string | null, payload: any } | null } | null };

export type StartResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  domain: Scalars['String'];
}>;


export type StartResetPasswordMutation = { __typename?: 'Mutation', startResetPassword?: { __typename?: 'StartResetPasswordPayload', id: string } | null };

export type ResetPasswordMutationVariables = Exact<{
  id: Scalars['String'];
  key: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'ResetPasswordPayload', id: string, state: string, jwt?: string | null, refresh?: string | null, user?: { __typename: 'User', id: number, username: string } | null, subscriptions?: Array<{ __typename?: 'Subscription', id: number, orgId?: number | null, userId: number, programId?: number | null, type: string, subtype?: string | null, state: SubscriptionStateEnum, payload: any, meta: any, createdAt: any, updatedAt: any, program?: { __typename?: 'Program', id: number, slug?: string | null, name: string, config?: any | null } | null } | null> | null, programs?: Array<{ __typename?: 'Program', id: number, slug?: string | null } | null> | null, userProfile?: { __typename?: 'UserProfile', id: number, firstName?: string | null, email?: string | null } | null } | null };

export type ValidateTokenQueryVariables = Exact<{
  id: Scalars['Int'];
  key: Scalars['String'];
}>;


export type ValidateTokenQuery = { __typename?: 'Query', validateToken?: { __typename?: 'Token', id: number, key: any, state: TokenStateEnum } | null };

export type ResubscribeMutationVariables = Exact<{
  userId: Scalars['Int'];
  orgId: Scalars['Int'];
  therapistId: Scalars['Int'];
  programId: Scalars['Int'];
}>;


export type ResubscribeMutation = { __typename?: 'Mutation', resubscribe?: { __typename?: 'CreateSubscriptionPayload', subscription?: { __typename?: 'Subscription', id: number, userId: number, orgId?: number | null, programId?: number | null, payload: any, state: SubscriptionStateEnum, meta: any } | null } | null };

export type UserRegistrationMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserRegistrationMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'RegisterUserPayload', refresh?: string | null, jwt?: string | null, user?: { __typename?: 'User', id: number, username: string } | null } | null };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription?: { __typename?: 'CreateSubscriptionPayload', subscription?: { __typename?: 'Subscription', id: number, type: string, subtype?: string | null, state: SubscriptionStateEnum, programId?: number | null, orgId?: number | null, userId: number, payload: any, meta: any, createdAt: any, updatedAt: any } | null } | null };

export type RegisterDetailsQueryVariables = Exact<{
  therapistId: Scalars['Int'];
  orgId: Scalars['Int'];
}>;


export type RegisterDetailsQuery = { __typename?: 'Query', registerDetails?: { __typename?: 'RegisterDetailsPayload', therapistName?: string | null, orgName?: string | null, therapistAvatar?: string | null } | null };

export type NoteAtVersionQueryVariables = Exact<{
  id: Scalars['Int'];
  versionId?: InputMaybe<Scalars['Int']>;
}>;


export type NoteAtVersionQuery = { __typename?: 'Query', note?: { __typename: 'Note', id: number, userId?: number | null, orgId?: number | null, type: string, state: NoteStateEnum, name: string, slug?: string | null, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any, atVersion?: { __typename?: 'Note', userId?: number | null, orgId?: number | null, type: string, state: NoteStateEnum, name: string, slug?: string | null, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null } | null };

export type NoteQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type NoteQuery = { __typename?: 'Query', note?: { __typename: 'Note', id: number, userId?: number | null, orgId?: number | null, type: string, state: NoteStateEnum, name: string, slug?: string | null, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null };

export type PublicNoteQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PublicNoteQuery = { __typename?: 'Query', note?: { __typename: 'Note', id: number, userId?: number | null, orgId?: number | null, type: string, state: NoteStateEnum, name: string, slug?: string | null, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null };

export type SubscribedProgramsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscribedProgramsQuery = { __typename?: 'Query', subscribedPrograms?: { __typename?: 'SubscribedProgramsPayload', subscriptions: Array<{ __typename?: 'Subscription', id: number, type: string, subtype?: string | null, state: SubscriptionStateEnum, programId?: number | null, orgId?: number | null, userId: number, payload: any, meta: any, createdAt: any, updatedAt: any, program?: { __typename?: 'Program', id: number, name: string, slug?: string | null, payload: any, config?: any | null, createdAt: any, updatedAt: any } | null }>, interactions: Array<{ __typename: 'Interaction', id: number, name: string, payload: any }> } | null };

export type AllStatesQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type AllStatesQuery = { __typename?: 'Query', states?: { __typename?: 'StatesConnection', nodes: Array<{ __typename: 'State', id: number, userId: number, state: StateStateEnum, programId?: number | null, interactionId?: number | null, payload: any, meta: any, createdAt: any, updatedAt: any } | null> } | null };


export const ProgramDocument = gql`
    query Program($id: Int!) {
  program(id: $id) {
    __typename
    id
    userId
    orgId
    type
    name
    slug
    payload
    meta
    isPublic
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useProgramQuery__
 *
 * To run a query within a React component, call `useProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProgramQuery(baseOptions: Apollo.QueryHookOptions<ProgramQuery, ProgramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgramQuery, ProgramQueryVariables>(ProgramDocument, options);
      }
export function useProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramQuery, ProgramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgramQuery, ProgramQueryVariables>(ProgramDocument, options);
        }
export type ProgramQueryHookResult = ReturnType<typeof useProgramQuery>;
export type ProgramLazyQueryHookResult = ReturnType<typeof useProgramLazyQuery>;
export type ProgramQueryResult = Apollo.QueryResult<ProgramQuery, ProgramQueryVariables>;
export function refetchProgramQuery(variables: ProgramQueryVariables) {
      return { query: ProgramDocument, variables: variables }
    }
export const ProgramWithPayloadDocument = gql`
    query ProgramWithPayload($organizationSlug: String!, $programSlug: String!, $sections: [String!]!, $versionId: Int) {
  programWithPayload(
    input: {programSlug: $programSlug, organizationSlug: $organizationSlug, sections: $sections, versionId: $versionId}
  ) {
    program {
      id
      name
      payload
      orgId
    }
    interactions {
      id
      name
      payload
    }
  }
}
    `;

/**
 * __useProgramWithPayloadQuery__
 *
 * To run a query within a React component, call `useProgramWithPayloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramWithPayloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramWithPayloadQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *      programSlug: // value for 'programSlug'
 *      sections: // value for 'sections'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useProgramWithPayloadQuery(baseOptions: Apollo.QueryHookOptions<ProgramWithPayloadQuery, ProgramWithPayloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgramWithPayloadQuery, ProgramWithPayloadQueryVariables>(ProgramWithPayloadDocument, options);
      }
export function useProgramWithPayloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramWithPayloadQuery, ProgramWithPayloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgramWithPayloadQuery, ProgramWithPayloadQueryVariables>(ProgramWithPayloadDocument, options);
        }
export type ProgramWithPayloadQueryHookResult = ReturnType<typeof useProgramWithPayloadQuery>;
export type ProgramWithPayloadLazyQueryHookResult = ReturnType<typeof useProgramWithPayloadLazyQuery>;
export type ProgramWithPayloadQueryResult = Apollo.QueryResult<ProgramWithPayloadQuery, ProgramWithPayloadQueryVariables>;
export function refetchProgramWithPayloadQuery(variables: ProgramWithPayloadQueryVariables) {
      return { query: ProgramWithPayloadDocument, variables: variables }
    }
export const PublicProgramDocument = gql`
    query PublicProgram($organizationSlug: String!, $slug: String!) {
  publicProgram(input: {organizationSlug: $organizationSlug, slug: $slug}) {
    id
    name
    slug
    config
  }
}
    `;

/**
 * __usePublicProgramQuery__
 *
 * To run a query within a React component, call `usePublicProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProgramQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicProgramQuery(baseOptions: Apollo.QueryHookOptions<PublicProgramQuery, PublicProgramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicProgramQuery, PublicProgramQueryVariables>(PublicProgramDocument, options);
      }
export function usePublicProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicProgramQuery, PublicProgramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicProgramQuery, PublicProgramQueryVariables>(PublicProgramDocument, options);
        }
export type PublicProgramQueryHookResult = ReturnType<typeof usePublicProgramQuery>;
export type PublicProgramLazyQueryHookResult = ReturnType<typeof usePublicProgramLazyQuery>;
export type PublicProgramQueryResult = Apollo.QueryResult<PublicProgramQuery, PublicProgramQueryVariables>;
export function refetchPublicProgramQuery(variables: PublicProgramQueryVariables) {
      return { query: PublicProgramDocument, variables: variables }
    }
export const StatesDocument = gql`
    query States($programId: Int, $userId: Int!) {
  states(filter: {programId: {equalTo: $programId}, userId: {equalTo: $userId}}) {
    nodes {
      __typename
      id
      userId
      state
      programId
      interactionId
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useStatesQuery__
 *
 * To run a query within a React component, call `useStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStatesQuery(baseOptions: Apollo.QueryHookOptions<StatesQuery, StatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options);
      }
export function useStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatesQuery, StatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options);
        }
export type StatesQueryHookResult = ReturnType<typeof useStatesQuery>;
export type StatesLazyQueryHookResult = ReturnType<typeof useStatesLazyQuery>;
export type StatesQueryResult = Apollo.QueryResult<StatesQuery, StatesQueryVariables>;
export function refetchStatesQuery(variables: StatesQueryVariables) {
      return { query: StatesDocument, variables: variables }
    }
export const CreateStateDocument = gql`
    mutation CreateState($userId: Int!, $programId: Int!, $interactionId: Int!, $state: StateStateEnum, $payload: JSON, $meta: JSON) {
  createState(
    input: {state: {userId: $userId, programId: $programId, interactionId: $interactionId, state: $state, payload: $payload, meta: $meta}}
  ) {
    state {
      __typename
      id
      userId
      programId
      interactionId
      state
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateStateMutationFn = Apollo.MutationFunction<CreateStateMutation, CreateStateMutationVariables>;

/**
 * __useCreateStateMutation__
 *
 * To run a mutation, you first call `useCreateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStateMutation, { data, loading, error }] = useCreateStateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      programId: // value for 'programId'
 *      interactionId: // value for 'interactionId'
 *      state: // value for 'state'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useCreateStateMutation(baseOptions?: Apollo.MutationHookOptions<CreateStateMutation, CreateStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStateMutation, CreateStateMutationVariables>(CreateStateDocument, options);
      }
export type CreateStateMutationHookResult = ReturnType<typeof useCreateStateMutation>;
export type CreateStateMutationResult = Apollo.MutationResult<CreateStateMutation>;
export type CreateStateMutationOptions = Apollo.BaseMutationOptions<CreateStateMutation, CreateStateMutationVariables>;
export const UpdateStateDocument = gql`
    mutation UpdateState($id: Int!, $state: StateStateEnum, $payload: JSON, $meta: JSON) {
  updateState(
    input: {id: $id, patch: {state: $state, payload: $payload, meta: $meta}}
  ) {
    state {
      __typename
      id
      userId
      programId
      interactionId
      state
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdateStateMutationFn = Apollo.MutationFunction<UpdateStateMutation, UpdateStateMutationVariables>;

/**
 * __useUpdateStateMutation__
 *
 * To run a mutation, you first call `useUpdateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateMutation, { data, loading, error }] = useUpdateStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useUpdateStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateMutation, UpdateStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStateMutation, UpdateStateMutationVariables>(UpdateStateDocument, options);
      }
export type UpdateStateMutationHookResult = ReturnType<typeof useUpdateStateMutation>;
export type UpdateStateMutationResult = Apollo.MutationResult<UpdateStateMutation>;
export type UpdateStateMutationOptions = Apollo.BaseMutationOptions<UpdateStateMutation, UpdateStateMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    event {
      __typename
      id
      userId
      programId
      interactionId
      type
      subtype
      name
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    event {
      __typename
      id
      userId
      programId
      interactionId
      type
      subtype
      name
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const EventsDocument = gql`
    query Events($condition: EventCondition, $first: Int, $offset: Int, $orderBy: [EventsOrderBy!]) {
  events(condition: $condition, first: $first, offset: $offset, orderBy: $orderBy) {
    nodes {
      id
      name
      type
      subtype
      userId
      programId
      interactionId
      payload
      meta
      createdAt
      updatedAt
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export function refetchEventsQuery(variables?: EventsQueryVariables) {
      return { query: EventsDocument, variables: variables }
    }
export const AuthenticateUserDocument = gql`
    mutation AuthenticateUser($username: String!, $password: String!, $remember: Boolean) {
  authenticateUser(
    input: {username: $username, password: $password, remember: $remember, role: "px"}
  ) {
    user {
      id
      username
      __typename
    }
    subscriptions {
      id
      orgId
      userId
      programId
      type
      subtype
      state
      payload
      meta
      createdAt
      updatedAt
      program {
        id
        slug
        name
        config
      }
    }
    programs {
      id
      slug
    }
    userProfile {
      id
      firstName
      email
    }
    jwt
    refresh
  }
}
    `;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<AuthenticateUserMutation, AuthenticateUserMutationVariables>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      remember: // value for 'remember'
 *   },
 * });
 */
export function useAuthenticateUserMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(AuthenticateUserDocument, options);
      }
export type AuthenticateUserMutationHookResult = ReturnType<typeof useAuthenticateUserMutation>;
export type AuthenticateUserMutationResult = Apollo.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refresh: String!, $remember: Boolean) {
  refreshToken(input: {refresh: $refresh, remember: $remember}) {
    jwt
    refresh
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refresh: // value for 'refresh'
 *      remember: // value for 'remember'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const SetEmailDocument = gql`
    mutation SetEmail($email: String!, $domain: String!) {
  setEmail(input: {email: $email, domain: $domain}) {
    jwt
    userProfile {
      id
      email
      payload
    }
  }
}
    `;
export type SetEmailMutationFn = Apollo.MutationFunction<SetEmailMutation, SetEmailMutationVariables>;

/**
 * __useSetEmailMutation__
 *
 * To run a mutation, you first call `useSetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmailMutation, { data, loading, error }] = useSetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useSetEmailMutation(baseOptions?: Apollo.MutationHookOptions<SetEmailMutation, SetEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEmailMutation, SetEmailMutationVariables>(SetEmailDocument, options);
      }
export type SetEmailMutationHookResult = ReturnType<typeof useSetEmailMutation>;
export type SetEmailMutationResult = Apollo.MutationResult<SetEmailMutation>;
export type SetEmailMutationOptions = Apollo.BaseMutationOptions<SetEmailMutation, SetEmailMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($id: String!, $key: String!) {
  verifyEmail(input: {id: $id, key: $key}) {
    jwt
    userProfile {
      id
      email
      payload
    }
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const SetMobileDocument = gql`
    mutation SetMobile($mobile: String!, $domain: String!) {
  setMobile(input: {mobile: $mobile, domain: $domain}) {
    jwt
    id
    userProfile {
      id
      mobile
      payload
    }
  }
}
    `;
export type SetMobileMutationFn = Apollo.MutationFunction<SetMobileMutation, SetMobileMutationVariables>;

/**
 * __useSetMobileMutation__
 *
 * To run a mutation, you first call `useSetMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMobileMutation, { data, loading, error }] = useSetMobileMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useSetMobileMutation(baseOptions?: Apollo.MutationHookOptions<SetMobileMutation, SetMobileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMobileMutation, SetMobileMutationVariables>(SetMobileDocument, options);
      }
export type SetMobileMutationHookResult = ReturnType<typeof useSetMobileMutation>;
export type SetMobileMutationResult = Apollo.MutationResult<SetMobileMutation>;
export type SetMobileMutationOptions = Apollo.BaseMutationOptions<SetMobileMutation, SetMobileMutationVariables>;
export const VerifyMobileDocument = gql`
    mutation VerifyMobile($id: String!, $key: String!) {
  verifyMobile(input: {id: $id, key: $key}) {
    jwt
    userProfile {
      id
      firstName
      mobile
      payload
    }
  }
}
    `;
export type VerifyMobileMutationFn = Apollo.MutationFunction<VerifyMobileMutation, VerifyMobileMutationVariables>;

/**
 * __useVerifyMobileMutation__
 *
 * To run a mutation, you first call `useVerifyMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMobileMutation, { data, loading, error }] = useVerifyMobileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useVerifyMobileMutation(baseOptions?: Apollo.MutationHookOptions<VerifyMobileMutation, VerifyMobileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyMobileMutation, VerifyMobileMutationVariables>(VerifyMobileDocument, options);
      }
export type VerifyMobileMutationHookResult = ReturnType<typeof useVerifyMobileMutation>;
export type VerifyMobileMutationResult = Apollo.MutationResult<VerifyMobileMutation>;
export type VerifyMobileMutationOptions = Apollo.BaseMutationOptions<VerifyMobileMutation, VerifyMobileMutationVariables>;
export const RequestOtpDocument = gql`
    mutation RequestOTP($mobile: String!, $domain: String!, $consent: Boolean!) {
  requestOTP(input: {mobile: $mobile, domain: $domain, consent: $consent}) {
    id
  }
}
    `;
export type RequestOtpMutationFn = Apollo.MutationFunction<RequestOtpMutation, RequestOtpMutationVariables>;

/**
 * __useRequestOtpMutation__
 *
 * To run a mutation, you first call `useRequestOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOtpMutation, { data, loading, error }] = useRequestOtpMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *      domain: // value for 'domain'
 *      consent: // value for 'consent'
 *   },
 * });
 */
export function useRequestOtpMutation(baseOptions?: Apollo.MutationHookOptions<RequestOtpMutation, RequestOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestOtpMutation, RequestOtpMutationVariables>(RequestOtpDocument, options);
      }
export type RequestOtpMutationHookResult = ReturnType<typeof useRequestOtpMutation>;
export type RequestOtpMutationResult = Apollo.MutationResult<RequestOtpMutation>;
export type RequestOtpMutationOptions = Apollo.BaseMutationOptions<RequestOtpMutation, RequestOtpMutationVariables>;
export const AuthenticateUserOtpDocument = gql`
    mutation AuthenticateUserOTP($id: String!, $otpKey: String!, $role: String!) {
  authenticateUserOTP(input: {id: $id, otpKey: $otpKey, role: $role}) {
    userProfile {
      id
      mobile
      firstName
      payload
    }
    user {
      id
      username
      __typename
    }
    subscriptions {
      id
      orgId
      userId
      programId
      type
      subtype
      state
      payload
      meta
      createdAt
      updatedAt
      program {
        id
        slug
        name
        config
      }
    }
    programs {
      id
      slug
    }
    jwt
    refresh
  }
}
    `;
export type AuthenticateUserOtpMutationFn = Apollo.MutationFunction<AuthenticateUserOtpMutation, AuthenticateUserOtpMutationVariables>;

/**
 * __useAuthenticateUserOtpMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserOtpMutation, { data, loading, error }] = useAuthenticateUserOtpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      otpKey: // value for 'otpKey'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAuthenticateUserOtpMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateUserOtpMutation, AuthenticateUserOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateUserOtpMutation, AuthenticateUserOtpMutationVariables>(AuthenticateUserOtpDocument, options);
      }
export type AuthenticateUserOtpMutationHookResult = ReturnType<typeof useAuthenticateUserOtpMutation>;
export type AuthenticateUserOtpMutationResult = Apollo.MutationResult<AuthenticateUserOtpMutation>;
export type AuthenticateUserOtpMutationOptions = Apollo.BaseMutationOptions<AuthenticateUserOtpMutation, AuthenticateUserOtpMutationVariables>;
export const SetFirstNameDocument = gql`
    mutation SetFirstName($firstName: String!) {
  setFirstName(input: {firstName: $firstName}) {
    jwt
    userProfile {
      id
      firstName
      payload
    }
  }
}
    `;
export type SetFirstNameMutationFn = Apollo.MutationFunction<SetFirstNameMutation, SetFirstNameMutationVariables>;

/**
 * __useSetFirstNameMutation__
 *
 * To run a mutation, you first call `useSetFirstNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFirstNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFirstNameMutation, { data, loading, error }] = useSetFirstNameMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *   },
 * });
 */
export function useSetFirstNameMutation(baseOptions?: Apollo.MutationHookOptions<SetFirstNameMutation, SetFirstNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFirstNameMutation, SetFirstNameMutationVariables>(SetFirstNameDocument, options);
      }
export type SetFirstNameMutationHookResult = ReturnType<typeof useSetFirstNameMutation>;
export type SetFirstNameMutationResult = Apollo.MutationResult<SetFirstNameMutation>;
export type SetFirstNameMutationOptions = Apollo.BaseMutationOptions<SetFirstNameMutation, SetFirstNameMutationVariables>;
export const StartResetPasswordDocument = gql`
    mutation StartResetPassword($email: String!, $domain: String!) {
  startResetPassword(input: {email: $email, domain: $domain}) {
    id
  }
}
    `;
export type StartResetPasswordMutationFn = Apollo.MutationFunction<StartResetPasswordMutation, StartResetPasswordMutationVariables>;

/**
 * __useStartResetPasswordMutation__
 *
 * To run a mutation, you first call `useStartResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startResetPasswordMutation, { data, loading, error }] = useStartResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useStartResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<StartResetPasswordMutation, StartResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartResetPasswordMutation, StartResetPasswordMutationVariables>(StartResetPasswordDocument, options);
      }
export type StartResetPasswordMutationHookResult = ReturnType<typeof useStartResetPasswordMutation>;
export type StartResetPasswordMutationResult = Apollo.MutationResult<StartResetPasswordMutation>;
export type StartResetPasswordMutationOptions = Apollo.BaseMutationOptions<StartResetPasswordMutation, StartResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($id: String!, $key: String!, $password: String!) {
  resetPassword(input: {id: $id, key: $key, password: $password}) {
    id
    state
    user {
      id
      username
      __typename
    }
    subscriptions {
      id
      orgId
      userId
      programId
      type
      subtype
      state
      payload
      meta
      createdAt
      updatedAt
      program {
        id
        slug
        name
        config
      }
    }
    programs {
      id
      slug
    }
    userProfile {
      id
      firstName
      email
    }
    jwt
    refresh
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      key: // value for 'key'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ValidateTokenDocument = gql`
    query ValidateToken($id: Int!, $key: String!) {
  validateToken(id: $id, key: $key) {
    id
    key
    state
  }
}
    `;

/**
 * __useValidateTokenQuery__
 *
 * To run a query within a React component, call `useValidateTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useValidateTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateTokenQuery, ValidateTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(ValidateTokenDocument, options);
      }
export function useValidateTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateTokenQuery, ValidateTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(ValidateTokenDocument, options);
        }
export type ValidateTokenQueryHookResult = ReturnType<typeof useValidateTokenQuery>;
export type ValidateTokenLazyQueryHookResult = ReturnType<typeof useValidateTokenLazyQuery>;
export type ValidateTokenQueryResult = Apollo.QueryResult<ValidateTokenQuery, ValidateTokenQueryVariables>;
export function refetchValidateTokenQuery(variables: ValidateTokenQueryVariables) {
      return { query: ValidateTokenDocument, variables: variables }
    }
export const ResubscribeDocument = gql`
    mutation Resubscribe($userId: Int!, $orgId: Int!, $therapistId: Int!, $programId: Int!) {
  resubscribe(
    input: {userId: $userId, orgId: $orgId, therapistId: $therapistId, programId: $programId}
  ) {
    subscription {
      id
      userId
      orgId
      programId
      payload
      state
      meta
    }
  }
}
    `;
export type ResubscribeMutationFn = Apollo.MutationFunction<ResubscribeMutation, ResubscribeMutationVariables>;

/**
 * __useResubscribeMutation__
 *
 * To run a mutation, you first call `useResubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubscribeMutation, { data, loading, error }] = useResubscribeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      orgId: // value for 'orgId'
 *      therapistId: // value for 'therapistId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useResubscribeMutation(baseOptions?: Apollo.MutationHookOptions<ResubscribeMutation, ResubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResubscribeMutation, ResubscribeMutationVariables>(ResubscribeDocument, options);
      }
export type ResubscribeMutationHookResult = ReturnType<typeof useResubscribeMutation>;
export type ResubscribeMutationResult = Apollo.MutationResult<ResubscribeMutation>;
export type ResubscribeMutationOptions = Apollo.BaseMutationOptions<ResubscribeMutation, ResubscribeMutationVariables>;
export const UserRegistrationDocument = gql`
    mutation UserRegistration($username: String!, $password: String!) {
  registerUser(input: {username: $username, password: $password, role: "px"}) {
    user {
      id
      username
    }
    refresh
    jwt
  }
}
    `;
export type UserRegistrationMutationFn = Apollo.MutationFunction<UserRegistrationMutation, UserRegistrationMutationVariables>;

/**
 * __useUserRegistrationMutation__
 *
 * To run a mutation, you first call `useUserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRegistrationMutation, { data, loading, error }] = useUserRegistrationMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<UserRegistrationMutation, UserRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRegistrationMutation, UserRegistrationMutationVariables>(UserRegistrationDocument, options);
      }
export type UserRegistrationMutationHookResult = ReturnType<typeof useUserRegistrationMutation>;
export type UserRegistrationMutationResult = Apollo.MutationResult<UserRegistrationMutation>;
export type UserRegistrationMutationOptions = Apollo.BaseMutationOptions<UserRegistrationMutation, UserRegistrationMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    subscription {
      id
      type
      subtype
      state
      programId
      orgId
      userId
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const RegisterDetailsDocument = gql`
    query RegisterDetails($therapistId: Int!, $orgId: Int!) {
  registerDetails(input: {therapistId: $therapistId, orgId: $orgId}) {
    therapistName
    orgName
    therapistAvatar
  }
}
    `;

/**
 * __useRegisterDetailsQuery__
 *
 * To run a query within a React component, call `useRegisterDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterDetailsQuery({
 *   variables: {
 *      therapistId: // value for 'therapistId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useRegisterDetailsQuery(baseOptions: Apollo.QueryHookOptions<RegisterDetailsQuery, RegisterDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisterDetailsQuery, RegisterDetailsQueryVariables>(RegisterDetailsDocument, options);
      }
export function useRegisterDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisterDetailsQuery, RegisterDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisterDetailsQuery, RegisterDetailsQueryVariables>(RegisterDetailsDocument, options);
        }
export type RegisterDetailsQueryHookResult = ReturnType<typeof useRegisterDetailsQuery>;
export type RegisterDetailsLazyQueryHookResult = ReturnType<typeof useRegisterDetailsLazyQuery>;
export type RegisterDetailsQueryResult = Apollo.QueryResult<RegisterDetailsQuery, RegisterDetailsQueryVariables>;
export function refetchRegisterDetailsQuery(variables: RegisterDetailsQueryVariables) {
      return { query: RegisterDetailsDocument, variables: variables }
    }
export const NoteAtVersionDocument = gql`
    query NoteAtVersion($id: Int!, $versionId: Int) {
  note(id: $id) {
    __typename
    id
    userId
    orgId
    type
    state
    name
    slug
    payload
    meta
    isPublic
    createdAt
    updatedAt
    atVersion(input: {versionId: $versionId}) {
      userId
      orgId
      type
      state
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useNoteAtVersionQuery__
 *
 * To run a query within a React component, call `useNoteAtVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAtVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAtVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useNoteAtVersionQuery(baseOptions: Apollo.QueryHookOptions<NoteAtVersionQuery, NoteAtVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteAtVersionQuery, NoteAtVersionQueryVariables>(NoteAtVersionDocument, options);
      }
export function useNoteAtVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteAtVersionQuery, NoteAtVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteAtVersionQuery, NoteAtVersionQueryVariables>(NoteAtVersionDocument, options);
        }
export type NoteAtVersionQueryHookResult = ReturnType<typeof useNoteAtVersionQuery>;
export type NoteAtVersionLazyQueryHookResult = ReturnType<typeof useNoteAtVersionLazyQuery>;
export type NoteAtVersionQueryResult = Apollo.QueryResult<NoteAtVersionQuery, NoteAtVersionQueryVariables>;
export function refetchNoteAtVersionQuery(variables: NoteAtVersionQueryVariables) {
      return { query: NoteAtVersionDocument, variables: variables }
    }
export const NoteDocument = gql`
    query Note($id: Int!) {
  note(id: $id) {
    __typename
    id
    userId
    orgId
    type
    state
    name
    slug
    payload
    meta
    isPublic
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useNoteQuery__
 *
 * To run a query within a React component, call `useNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteQuery(baseOptions: Apollo.QueryHookOptions<NoteQuery, NoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
      }
export function useNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteQuery, NoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
        }
export type NoteQueryHookResult = ReturnType<typeof useNoteQuery>;
export type NoteLazyQueryHookResult = ReturnType<typeof useNoteLazyQuery>;
export type NoteQueryResult = Apollo.QueryResult<NoteQuery, NoteQueryVariables>;
export function refetchNoteQuery(variables: NoteQueryVariables) {
      return { query: NoteDocument, variables: variables }
    }
export const PublicNoteDocument = gql`
    query PublicNote($id: Int!) {
  note(id: $id) {
    __typename
    id
    userId
    orgId
    type
    state
    name
    slug
    payload
    meta
    isPublic
    createdAt
    updatedAt
  }
}
    `;

/**
 * __usePublicNoteQuery__
 *
 * To run a query within a React component, call `usePublicNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublicNoteQuery(baseOptions: Apollo.QueryHookOptions<PublicNoteQuery, PublicNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicNoteQuery, PublicNoteQueryVariables>(PublicNoteDocument, options);
      }
export function usePublicNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicNoteQuery, PublicNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicNoteQuery, PublicNoteQueryVariables>(PublicNoteDocument, options);
        }
export type PublicNoteQueryHookResult = ReturnType<typeof usePublicNoteQuery>;
export type PublicNoteLazyQueryHookResult = ReturnType<typeof usePublicNoteLazyQuery>;
export type PublicNoteQueryResult = Apollo.QueryResult<PublicNoteQuery, PublicNoteQueryVariables>;
export function refetchPublicNoteQuery(variables: PublicNoteQueryVariables) {
      return { query: PublicNoteDocument, variables: variables }
    }
export const SubscribedProgramsDocument = gql`
    query SubscribedPrograms {
  subscribedPrograms {
    subscriptions {
      id
      type
      subtype
      state
      programId
      orgId
      userId
      payload
      meta
      createdAt
      updatedAt
      program {
        id
        name
        slug
        payload
        config
        createdAt
        updatedAt
      }
    }
    interactions {
      __typename
      id
      name
      payload
    }
  }
}
    `;

/**
 * __useSubscribedProgramsQuery__
 *
 * To run a query within a React component, call `useSubscribedProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribedProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribedProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscribedProgramsQuery(baseOptions?: Apollo.QueryHookOptions<SubscribedProgramsQuery, SubscribedProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscribedProgramsQuery, SubscribedProgramsQueryVariables>(SubscribedProgramsDocument, options);
      }
export function useSubscribedProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscribedProgramsQuery, SubscribedProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscribedProgramsQuery, SubscribedProgramsQueryVariables>(SubscribedProgramsDocument, options);
        }
export type SubscribedProgramsQueryHookResult = ReturnType<typeof useSubscribedProgramsQuery>;
export type SubscribedProgramsLazyQueryHookResult = ReturnType<typeof useSubscribedProgramsLazyQuery>;
export type SubscribedProgramsQueryResult = Apollo.QueryResult<SubscribedProgramsQuery, SubscribedProgramsQueryVariables>;
export function refetchSubscribedProgramsQuery(variables?: SubscribedProgramsQueryVariables) {
      return { query: SubscribedProgramsDocument, variables: variables }
    }
export const AllStatesDocument = gql`
    query AllStates($userId: Int!) {
  states(filter: {userId: {equalTo: $userId}}) {
    nodes {
      __typename
      id
      userId
      state
      programId
      interactionId
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useAllStatesQuery__
 *
 * To run a query within a React component, call `useAllStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllStatesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllStatesQuery(baseOptions: Apollo.QueryHookOptions<AllStatesQuery, AllStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllStatesQuery, AllStatesQueryVariables>(AllStatesDocument, options);
      }
export function useAllStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllStatesQuery, AllStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllStatesQuery, AllStatesQueryVariables>(AllStatesDocument, options);
        }
export type AllStatesQueryHookResult = ReturnType<typeof useAllStatesQuery>;
export type AllStatesLazyQueryHookResult = ReturnType<typeof useAllStatesLazyQuery>;
export type AllStatesQueryResult = Apollo.QueryResult<AllStatesQuery, AllStatesQueryVariables>;
export function refetchAllStatesQuery(variables: AllStatesQueryVariables) {
      return { query: AllStatesDocument, variables: variables }
    }